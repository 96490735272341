import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import TopBar from "./TopBar/index";
import { THEMES } from "../../constants";
import useFullscreen from "../../hooks/useFullscreen";
import { motion } from 'framer-motion/dist/framer-motion';

const animations = {
  initial: { opacity: 0, x: 0 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 0 },
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    minHeight: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
    // [theme.breakpoints.up("lg")]: {
    //   paddingLeft: 10,
    // },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
    backgroundColor: theme.palette.background.default,
  },
}));

const SidebarLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const picRef = useRef(null)
  const [full, setFull] = useFullscreen(picRef)

  return (
    <div className={classes.root}>
      {full ?
        <>
        </>
        :
        <TopBar
          onMobileNavOpen={() => setMobileNavOpen(true)}
          openMobile={isMobileNavOpen}
          setFullscreen={setFull}
          fullscreen={full}
        />
      }
      <div className={classes.wrapper}>
        <div className={classes.contentContainer} class={full ? 'fullscreen' : classes.contentContainer} ref={picRef}>
          <div className={classes.content}>
            <motion.div
              variants={animations}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.25 }}
            >
              {children}
            </motion.div>
          </div>
        </div>
      </div>
    </div >
  );
};

SidebarLayout.propTypes = {
  children: PropTypes.node,
};

export default SidebarLayout;
