import React, { useState, useEffect } from "react";
import { Chip, Typography, Grid, Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import StandardCard from "./StandardCard";
import moment from "moment";
import {
  LocationOnRounded,
  LocationOffRounded,
  PersonRounded,
  EmojiObjectsOutlined as Identified,
  HourglassEmptyRounded as Initial,
  SearchRounded as Investigating,
  ErrorOutlineRounded as DegradedPerformance,
  CheckCircleOutlineRounded as Operational,
  HighlightOffRounded as MajorOutage,
} from "@material-ui/icons";
import { LocationOn, WarningAmberRounded } from "@mui/icons-material";
import { DoneRounded, PriorityHighRounded, BlockRounded, InfoRounded, WarningRounded, ErrorRounded } from '@material-ui/icons';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
import { a } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
    display: 'inline-flex',
    textAlign: "left",
    paddingBottom: '0px !important'
  },
  extraTitle: {
    fontSize: 14,
    display: 'inline-flex',
    textAlign: "left",
    paddingBottom: '0px !important'
  },
  subtitle: {
    paddingBottom: '12px'
  },
  serviceTitle: {
    marginLeft: 10,
  },
  customerImpact: {
    // whiteSpace: 'pre-wrap',
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2, /* number of lines to show */
    lineClamp: 2,
    "-webkit-box-orient": "vertical",
  },
  openForTimer: {
    backgroundColor: theme.palette.common.grayT5,
    color: theme.palette.common.black,
    padding: 12,
    textAlign: 'center',
    borderRadius: '24px',
  },
  openForTimeSection: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  lastUpdated: {
    backgroundColor: theme.palette.common.grayT5,
    color: theme.palette.common.black,
    padding: 12,
    textAlign: 'center',
    borderRadius: '24px',
  },
  pos: {
    marginBottom: 12,
    fontSize: 12,
  },
  statusChip: {
    marginBottom: 6,
  },
  statusSeeMore: {
    textTransform: "none",
  },
  borderCard: {
    borderStyle: "solid",
    borderColor: theme.palette.background.default,
    padding: "16px",
  },
  chipGreen: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white
  },
  chipFYI: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white
  },
  chipMinor: {
    backgroundColor: theme.palette.focus.main,
    color: theme.palette.common.black
  },
  chipMajor: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.black
  },
  chipCritical: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white
  },
  regionNone: {
    backgroundColor: theme.palette.common.grayT5,
    opacity: 0.5,
    color: theme.palette.common.black
  },
  // animatedItem: {
  //   opacity: 1,
  // },
  regions: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  region: {
    display: "flex",
    minHeight: 75,
    borderRadius: '24px',
    fontSize: 14,
    fontWeight: 'bold'
  },
  gridItem: {
    margin: 'auto',
    width: '50%'
  },
  cardItem: {
    display: "flex",
    justifyContent: "center",
  },
  // separator: {
  //   animation: '$blinker 3s linear infinite',
  // },
  // '@keyframes blinker': {
  //   '50%': { opacity: 0, }
  // },
  card: {
    // Use flex layout with column direction for components in the card
    // (CardContent and CardActions)
    display: "flex",

    // Justify the content so that CardContent will always be at the top of the card,
    // and CardActions will be at the bottom
    //justifyContent: "space-between",
    height: "100%",
  },
  criticalIncidents: {
    borderColor: theme.palette.background.default,
    borderStyle: 'solid',
    borderWidth: 4,
    borderRadius: 48,
    animation: `$criticalEffect 2500ms ${theme.transitions.easing.easeInOut}`,
    animationIterationCount: 'infinite',
    padding: "16px",
  },
  majorIncidents: {
    borderColor: theme.palette.background.default,
    borderStyle: 'solid',
    borderWidth: 4,
    borderRadius: 48,
    animation: `$majorEffect 2500ms ${theme.transitions.easing.easeInOut}`,
    animationIterationCount: 'infinite',
    padding: "16px",
  },
  minorIncidents: {
    borderColor: theme.palette.background.default,
    borderStyle: 'solid',
    borderWidth: 4,
    borderRadius: 48,
    animation: `$minorEffect 2500ms ${theme.transitions.easing.easeInOut}`,
    animationIterationCount: 'infinite',
    padding: "16px",
  },
  fyiIncidents: {
    borderColor: theme.palette.background.default,
    borderStyle: 'solid',
    borderWidth: 4,
    borderRadius: 48,
    animation: `$fyiEffect 2500ms ${theme.transitions.easing.easeInOut}`,
    animationIterationCount: 'infinite',
    padding: "16px",
  },
  "@keyframes criticalEffect": {
    "0%": {
      borderColor: theme.palette.error.main,
    },
    "50%": {
      borderColor: 'transparent'
    },
    "100%": {
      borderColor: theme.palette.error.main,
    }
  },
  "@keyframes majorEffect": {
    "0%": {
      borderColor: theme.palette.warning.main,
    },
    "50%": {
      borderColor: 'transparent'
    },
    "100%": {
      borderColor: theme.palette.warning.main,
    }
  },
  "@keyframes minorEffect": {
    "0%": {
      borderColor: theme.palette.focus.main,
    },
    "50%": {
      borderColor: 'transparent'
    },
    "100%": {
      borderColor: theme.palette.focus.main,
    }
  },
  "@keyframes fyiEffect": {
    "0%": {
      borderColor: theme.palette.info.main,
    },
    "50%": {
      borderColor: 'transparent'
    },
    "100%": {
      borderColor: theme.palette.info.main,
    }
  }
}));

const initRecentlyUpdated = false;
const initIsAnimated = false;
const initOpenFor = {
  days: "0",
  hours: "0",
  minutes: "0",
};

const DashboardDetailedIncidentCard = ({ service, user }) => {
  const classes = useStyles();
  const [cardService, setCardService] = useState({});
  const [recentlyUpdated, setRecentlyUpdated] = useState(initRecentlyUpdated);
  const [isAnimated, setIsAnimated] = useState(initIsAnimated)
  const [incidentOpenFor, setincidentOpenFor] = useState(initOpenFor);

  const getLatestUpdate = () => {
    const emailUpdates = cardService?.Incident?.emailUpdates
    var parsedUpdates = []
    var body = 'none'
    if (emailUpdates != undefined || emailUpdates != null) {
      parsedUpdates = JSON.parse(emailUpdates)
      body = parsedUpdates[parsedUpdates.length - 1]?.body
      body = body.replace(/<\/?[^>]+(>|$)/g, " ");
    }
    return body
  }

  const regionConstants = {
    Europe: "EU",
    "North America": "NA",
    "South America": "SA",
    "Asia Pacific": "APAC",
    "Middle East and Africa": "MEA",
  };

  const statusConstants = {
    "Major Outage": "This service is experiencing a full outage.",
    "Partial Outage": "This service is experiencing a partial outage.",
    "Degraded Performance":
      "This service is experiencing degraded performance.",
    Operational: "This service is operational.",
  };

  const handleUpdateStatusIcon = () => {
    switch (service?.Incident?.updateStatus) {
      case "Identified":
        return (
          <Icon>
            <Identified />
          </Icon>
        );
      case "Investigating":
        return (
          <Icon>
            <Investigating />
          </Icon>
        );
      default:
        return (
          <Icon>
            <Initial />
          </Icon>
        );
    }
  };

  const handleServiceStatusIcon = () => {
    switch (service?.Incident?.serviceStatus) {
      case "Major Outage":
        return (
          <Icon>
            <MajorOutage />
          </Icon>
        );
      case "Partial Outage":
        return (
          <Icon>
            <WarningAmberRounded />
          </Icon>
        );
      case "Degraded Performance":
        return (
          <Icon>
            <DegradedPerformance />
          </Icon>
        );
      case "Operational":
        return (
          <Icon>
            <Operational />
          </Icon>
        );
      default:
        return (
          <Icon>
            <Operational />
          </Icon>
        );
    }
  };

  const handleStatusChip = (size) => {
    switch (service?.Incident?.classification) {
      case "FYI":
        return (
          <Chip
            icon={<InfoRounded />}
            label="FYI"
            color="primary"
            size={size}
            className={clsx(classes.animatedItem, classes.chipFYI)}
          />
        );
      case "Minor":
        return (
          <Chip
            icon={<ErrorRounded />}
            label="Minor"
            color="primary"
            size={size}
            className={clsx(classes.animatedItem, classes.chipMinor)}
          />
        );
      case "Major":
        return (
          <Chip
            icon={<WarningRounded />}
            label="Major"
            color="primary"
            size={size}
            className={clsx(classes.animatedItem, classes.chipMajor)}
          />
        );
      case "Critical":
        return (
          <Chip
            icon={<DangerousRoundedIcon />}
            label="Critical"
            color="primary"
            size={size}
            className={clsx(classes.animatedItem, classes.chipCritical)}
          />
        );
      default:
        return (
          <Chip
            icon={<DoneRounded />}
            label="Operational"
            color="primary"
            size={size}
            className={clsx(classes.animatedItem, classes.chipGreen)}
          />
        );
    }
  };

  const handleCard = () => {
    if (isAnimated) {
      switch (service?.Incident?.classification) {
        case "FYI":
          return (
            <StandardCard cardContent={cardContent} className={classes.fyiIncidents} />
          );
        case "Minor":
          return (
            <StandardCard cardContent={cardContent} className={classes.minorIncidents} />
          );
        case "Major":
          return (
            <StandardCard cardContent={cardContent} className={classes.majorIncidents} />
          );
        case "Critical":
          return (
            <StandardCard cardContent={cardContent} className={classes.criticalIncidents} />
          );
        default:
          return (
            <StandardCard cardContent={cardContent} className={classes.borderCard} />
          );
      }
    }
    return (
      <StandardCard cardContent={cardContent} className={classes.borderCard} />
    );
  };

  const handleRegionChip = (region) => {
    var regions = service?.Incident?.regions;
    var serviceRegions = service?.Regions;
    if (regions !== null && regions !== undefined) {
      regions = JSON.parse(regions);
      if (
        serviceRegions[region] === "NotAvailable" ||
        serviceRegions[region] === "Pending"
      ) {
        return (
          <Grid item xs={2}>
            <Chip
              icon={<LocationOffRounded />}
              label={regionConstants[region]}
              color="primary"
              className={clsx(
                classes.animatedItem,
                classes.regionNone,
                classes.region
              )}
            />
          </Grid>
        );
      }
      if (
        regions[region] !== null &&
        regions[region] !== undefined
      ) {
        return (
          <Grid item xs={2}>
            <Chip
              icon={<LocationOffRounded />}
              label={regionConstants[region]}
              color="primary"
              className={clsx(
                classes.animatedItem,
                classes.chipCritical,
                classes.region
              )}
            />
          </Grid>
        );
      }
    }
    return (
      <Grid item xs={2}>
        <Chip
          icon={<LocationOnRounded />}
          label={regionConstants[region]}
          color="primary"
          className={clsx(
            classes.animatedItem,
            classes.chipGreen,
            classes.region
          )}
        />
      </Grid>
    );
  };

  useEffect(() => {
    setCardService(service);
  }, [service]);

  useEffect(() => {
    const interval = setInterval(() => {
      handleDate(service);
      const newUpdate = Math.abs(new Date() - moment.utc(service?.Incident?.updatedAt)) < 300000
      if (newUpdate) {
        setIsAnimated(true);
        // const timer = setTimeout(() => {
        //   console.log('This has set a new timeout')
        //   setIsAnimated(false);
        // }, 12000);
        // return () => clearTimeout(timer);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [service]);

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  const handleDate = () => {
    const formatTime = (time) => {
      return time < 10 ? `0${time}` : time;
    };

    const date1 = new Date();
    const date2 = new Date(moment.utc(service.Incident?.createdAt));
    const milliseconds = Math.abs(date1 - date2);

    var countDays = 24 * 60 * 60 * 1000;
    var countHours = 60 * 60 * 1000;
    var days = Math.floor(milliseconds / countDays);
    var hours = Math.floor((milliseconds - days * countDays) / countHours);
    var minutes = Math.round(
      (milliseconds - days * countDays - hours * countHours) / 60000
    );
    if (minutes === 60) {
      hours++;
      minutes = 0;
    }
    if (hours === 24) {
      days++;
      hours = 0;
    }
    setincidentOpenFor({ days, hours, minutes });
  };

  const cardContent = (
    <Grid container spacing={3} className={classes.card}>
      <Grid item xs={4} className={classes.title}>
        <div className={classes.statusChip}>{handleStatusChip("medium")}</div>
        <Typography
          variant="h3"
          className={classes.serviceTitle}
        >
          {cardService.ServiceAssetName}
        </Typography>
      </Grid>
      <Grid item xs={2} className={classes.extraTitle}>
        <Icon>
          <PersonRounded />
        </Icon>
        <Typography
          variant="h3"
          className={classes.serviceTitle}
        >
          {cardService?.LiveServicesManager}
        </Typography>
      </Grid>
      <Grid item xs={2} className={classes.extraTitle}>
        {handleUpdateStatusIcon()}
        <Typography
          variant="h3"
          className={classes.serviceTitle}
        >
          {cardService?.Incident?.updateStatus ? cardService?.Incident?.updateStatus : 'Initial Comms'}
        </Typography>
      </Grid>
      <Grid item xs={2} className={classes.extraTitle}>
        {handleServiceStatusIcon()}
        <Typography
          variant="h3"
          className={classes.serviceTitle}
        >
          {cardService?.Incident?.serviceStatus}
        </Typography>
      </Grid>
      <Grid
        item xs={4}
        className={classes.gridItem}
      >
        {cardService?.Incident?.customerImpact != undefined && cardService?.Incident?.customerImpact != 'none' && cardService?.Incident?.customerImpact != null ?
          <React.Fragment>
            <Typography
              variant="h4"
              className={classes.subtitle}
            >
              Customer Impact
            </Typography>
            <Typography
              variant="h5"
              className={classes.customerImpact}
            >
              {cardService?.Incident?.customerImpact}
            </Typography>
            <br />
            <Typography
              variant="h4"
              className={classes.subtitle}
            >
              Latest Update
            </Typography>
            <Typography
              variant="h5"
              className={classes.customerImpact}
            >
              {
                getLatestUpdate()
              }
            </Typography>
          </React.Fragment>
          :
          <React.Fragment>
            <Typography
              variant="h4"
              className={classes.subtitle}
            >
              Latest Update
            </Typography>
            <Typography
              variant="h5"
              className={classes.customerImpact}
            >
              {
                getLatestUpdate()
              }
            </Typography>
          </React.Fragment>
        }
      </Grid>
      <Grid
        item xs={2}
        className={classes.gridItem}
      >
        <Typography
          variant="h4"
          className={classes.subtitle}
        >
          Last Updated At (UTC)
        </Typography>
        <Grid
          container spacing={0}
          className={classes.lastUpdated}
        >
          <Grid
            item xs={12}
          >
            <Typography
              variant="h3"
            >
              <span>{moment(cardService?.Incident?.updatedAt).format("HH")}</span>
              <span className={classes.separator}>:</span>
              <span>{moment(cardService?.Incident?.updatedAt).format("mm")}</span>
            </Typography>
          </Grid>
          <Grid
            item xs={12}
          >
            <Typography
              variant="h6"
            >
              <span>{moment(cardService?.Incident?.updatedAt).format("Do MMMM YYYY")}</span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item xs={2}
        className={classes.gridItem}
      >
        <Typography
          variant="h4"
          className={classes.subtitle}
        >
          Incident Open For
        </Typography>
        <Grid
          container spacing={0}
          className={classes.openForTimer}
        >
          <Grid
            item xs={12}
            className={classes.openForTimeSection}
          >
            <Grid
              item xs={3}
              className={classes.openForTimeSection}
            >
              <Typography
                variant="h3"
              >
                {`${incidentOpenFor.days}`}
              </Typography>
            </Grid>
            <Grid
              item xs={1}
              className={classes.openForTimeSection}
            >
              <Typography
                variant="h3"
              >
                <span className={classes.separator}>:</span>
              </Typography>
            </Grid>
            <Grid
              item xs={3}
              className={classes.openForTimeSection}
            >
              <Typography
                variant="h3"
              >
                {`${incidentOpenFor.hours}`}
              </Typography>
            </Grid>
            <Grid
              item xs={1}
              className={classes.openForTimeSection}
            >
              <Typography
                variant="h3"
              >
                <span className={classes.separator}>:</span>
              </Typography>
            </Grid>
            <Grid
              item xs={4}
              className={classes.openForTimeSection}
            >
              <Typography
                variant="h3"
              >
                {`${incidentOpenFor.minutes}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item xs={12}
            className={classes.openForTimeSection}
          >
            <Grid
              item xs={3}
              className={classes.openForTimeSection}
            >
              <Typography
                variant="h6"
              >
                days
              </Typography>
            </Grid>
            <Grid
              item xs={1}
            >
            </Grid>
            <Grid
              item xs={3}
              className={classes.openForTimeSection}
            >
              <Typography
                variant="h6"
              >
                hours
              </Typography>
            </Grid>
            <Grid
              item xs={1}
            >
            </Grid>
            <Grid
              item xs={4}
              className={classes.openForTimeSection}
            >
              <Typography
                variant="h6"
              >
                mins
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item xs={4}
        className={classes.gridItem}
      >
        <Typography
          variant="h4"
          className={classes.subtitle}
        >
          Affected Regions
        </Typography>
        <div className={classes.regions}>
          {handleRegionChip("Europe")}
          {handleRegionChip("North America")}
          {handleRegionChip("South America")}
          {handleRegionChip("Asia Pacific")}
          {handleRegionChip("Middle East and Africa")}
        </div>
      </Grid>
    </Grid>
  );

  return (
    handleCard()
  );
};

export default DashboardDetailedIncidentCard;
