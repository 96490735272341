import React, { createContext, useState, useContext, useEffect, useReducer } from "react";
import useAuth from "../hooks/useAuth";
import { serviceNowAssignmentGroupsGet, serviceNowManagersGet } from "../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";

const initialServiceNowFormFieldsState = {
    assignmentGroups: [],
    managers: []
};

const reducer = (state, action) => {
    switch (action.type) {
        case "INITIALISE_GROUPS": {
            const { assignmentGroups } = action.payload;

            return {
                ...state,
                assignmentGroups: assignmentGroups,
            };
        }
        case "INITIALISE_MANAGERS": {
            const { managers } = action.payload;

            return {
                ...state,
                managers: managers,
            };
        }
        default: {
            return { ...state };
        }
    }
};

const ServiceNowFormFieldsContext = createContext({
    ...initialServiceNowFormFieldsState,
});

// We wrap the provider in a nice little component
// which will hold the state and provide methods to
// update the state
const ServiceNowFormFieldsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialServiceNowFormFieldsState);

    const { user } = useAuth();

    const getAssignmentGroups = async () => {

        try {
            const req = await API.graphql(
                graphqlOperation(serviceNowAssignmentGroupsGet, {
                    token: user.token
                })
            );
            var res = JSON.parse(req?.data?.serviceNowAssignmentGroupsGet);
            var data = res?.options
            return data
        } catch (err) {
            console.error(err);
        }
        return data;
    };

    const getManagers = async () => {

        try {
            const req = await API.graphql(
                graphqlOperation(serviceNowManagersGet, {
                    token: user.token
                })
            );
            var res = JSON.parse(req?.data?.serviceNowManagersGet);
            var data = res?.options
            return data
        } catch (err) {
            console.error(err);
        }
        return data;
    };

    useEffect(() => {
        const initData = async () => {
            try {
                const groups = await getAssignmentGroups();
                if (groups) {
                    dispatch({
                        type: "INITIALISE_GROUPS",
                        payload: {
                            assignmentGroups: groups,
                        },
                    });
                }
                const managers = await getManagers();
                if (managers) {
                    dispatch({
                        type: "INITIALISE_MANAGERS",
                        payload: {
                            managers: managers,
                        },
                    });
                }
            } catch (err) {
                console.error(err);
            }
        };
        initData();
    }, []);

    return (<ServiceNowFormFieldsContext.Provider
        value={{
            ...state,
        }}
    > {children} </ServiceNowFormFieldsContext.Provider>);
}

// Here we create a custom hook that allows us to consume
// the context
function useServiceNowFormFieldsContext() {
    return useContext(ServiceNowFormFieldsContext);
}

export { ServiceNowFormFieldsProvider, useServiceNowFormFieldsContext };