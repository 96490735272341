import React, { useEffect, useRef, useState, Fragment } from "react";
import clsx from "clsx";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Container,
  makeStyles,
  Avatar,
  ListItemAvatar,
  Typography,
  useTheme,
} from "@material-ui/core";
import Page from "../../../components/Pages/Page";
import Segment from "./segment";
import useServices from "../../../hooks/useServiceRegister";
import DashboardServiceCard from "../../../components/Cards/DashboardServiceCard";
import StandardCard from "../../../components/Cards/StandardCard";
import IncidentOrUptime from "./IncidentOrUptime/index";
import IncidentOverviewSmall from "./IncidentOverviewSmall";
import DarkLogo from "../../../media/logos/logo512-green.png";
import LightLogo from "../../../media/logos/logo512-black.png";
import { THEMES } from "../../../constants";
import { DoneRounded } from "@material-ui/icons";
import { API, graphqlOperation } from "aws-amplify";
import { realTimeIncidents } from "../../../graphql/queries";
import Carousel from 'react-material-ui-carousel'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  primary: {
    // light: will be calculated from palette.primary.main,
    main: "#ff4400",
    // dark: will be calculated from palette.primary.main,
    // contrastText: will be calculated to contrast with palette.primary.main
  },
  secondary: {
    light: "#0066ff",
    main: "#0044ff",
    // dark: will be calculated from palette.secondary.main,
    contrastText: "#ffcc00",
  },
  cardsIncident: {
    padding: theme.spacing(2),
    //height: "80%",
    marginBottom: 10,
    display: "flex",
    flexDirection: "column",
    //height: '20vw',
    minHeight: "20vw",
  },
  boxLogo: {
    marginLeft: "auto",
    marginRight: "auto",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  timeCard: {
    height: "20%",
  },
  gridItem: {
    display: "grid",
    padding: 12,
  },
  listItemAvatarGreen: {
    backgroundColor: theme.palette.success.main,
    marginRight: "10px",
  },
  currentIncidents: {
    display: "grid",
    [theme.breakpoints.up("md")]: {
      minHeight: "350px",
    },
  },
  carousel: {
  }
}));

const ServiceRegisterView = () => {
  const classes = useStyles();
  const { services } = useServices();
  const servicesRet = services;
  const [inIncident, setInIncident] = useState(false);
  const [hexsize, setHexsize] = useState("32");
  const [incidentServices, SetIncidentServices] = useState(servicesRet);
  const [unresolvedIncidents, setUnresolvedIncidents] = useState([]);

  const status = ["minor", "major", "critical"];

  const getServices = (serviceArray) => {
    const services = serviceArray?.map((service) => {
      const incidentId = unresolvedIncidents?.filter((incident) => {
        var serviceIncident;
        if (service.Id === incident.serviceRegisterId) {
          serviceIncident = incident;
        }
        return serviceIncident;
      });
      if (service["Incident"] && incidentId.length === 0) {
        var newService = {
          ...service,
          classification: "Operational",
        };
        delete newService?.Incident
        return newService;
      }
      if (incidentId.length > 0) {
        var newService = {
          ...service,
          classification: incidentId[0].classification,
          Incident: {
            ...incidentId[0],
          },
        };
        return newService;
      }
      var newService = {
        ...service,
        classification: "Operational",
      };
      return newService;
    });

    const order = { Critical: 1, Major: 2, Minor: 3, FYI: 4, default: 1000 }
    const sortedServices = services?.sort(function (a, b) {
      var x = a?.classification; var y = b?.classification;
      return (order[x] || order.default) - (order[y] || order.default);
    });

    return sortedServices;
  };

  var incidentsInRealTime;

  const fetchData = async () => {
    incidentsInRealTime = await API.graphql(
      graphqlOperation(realTimeIncidents)
    );
    //console.log('realtime', incidentsInRealTime)
    if (incidentsInRealTime.data.realTimeIncidents !== null) {
      //console.log(incidentsInRealTime);
      setUnresolvedIncidents(() => [
        ...incidentsInRealTime.data.realTimeIncidents,
      ]);
    }
  };

  const fetchDataPeriodically = async () => {
    const newIncidentArray = await API.graphql(
      graphqlOperation(realTimeIncidents)
    );
    if (newIncidentArray.data.realTimeIncidents !== null) {
      if (newIncidentArray.data.realTimeIncidents.length != incidentsInRealTime.data.realTimeIncidents.length) {
        setUnresolvedIncidents(() => [...newIncidentArray.data.realTimeIncidents]);
        incidentsInRealTime.data.realTimeIncidents.push(...newIncidentArray.data.realTimeIncidents);
      }
    }
  }


  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchDataPeriodically();
    }, 10000);
  }, []);

  useEffect(() => {
    SetIncidentServices(getServices(servicesRet));
  }, [servicesRet]);

  useEffect(() => {
    //console.log('unresolvedIncidents', unresolvedIncidents)
    SetIncidentServices(getServices(incidentServices));
  }, [unresolvedIncidents]);

  useEffect(() => {
    if (
      incidentServices?.filter(
        (x) =>
          x?.Incident?.classification === "Minor" ||
          x?.Incident?.classification === "Major" ||
          x?.Incident?.classification === "Critical"
      ).length > 0
    ) {
      setInIncident(true);
    }
    else {
      setInIncident(false);
    }
  }, [incidentServices]);

  useEffect(() => {
    if (inIncident === true) {
      setHexsize("27.5");
    } else {
      setHexsize("32");
    }
  }, [inIncident]);

  const picture = (
    <Grid container spacing={3}>
      <Box p={2} className={classes.boxLogo}>
        <img
          src={useTheme().name === THEMES.LIGHT ? LightLogo : DarkLogo}
          alt="Sage Cloud Ops Portal"
          width="50%"
        />
      </Box>
      <Box p={2} className={classes.boxLogo}>
        <Avatar className={classes.listItemAvatarGreen}>
          <DoneRounded />
        </Avatar>
        <Typography variant="h3">All Services Operational</Typography>
      </Box>
    </Grid>
  );

  const getIncidents = (size) => {
    const incidents = incidentServices?.filter((service) => {
      if (service?.Incident?.classification) {
        return true;
      }
      return false;
    });
    const cards = incidents?.map((service) => {
      return (
        <Grid
          item
          xl={4}
          lg={6}
          md={6}
          xs={6}
          className={classes.gridItem}
        >
          <DashboardServiceCard
            service={service.ServiceAssetName}
            segmentLead={service.LiveServicesManager}
            stats={service.Incident?.classification}
            className={classes.card}
          />
        </Grid>
      );
    });

    var displayCardsArray = []
    for (var i = 0; i < cards?.length; i += size) {
      const displayCardSection = (
        <Grid container spacing={3}>
          {cards.slice(i, i + size)}
        </Grid>
      )
      displayCardsArray.push(displayCardSection);
    }
    return displayCardsArray
  };

  const cardContent = (
    <React.Fragment>
      {inIncident ? (
        <Carousel
          animation='slide'
          interval='15000'
          duration='3000'
          className={classes.carousel}
        >
            {
              getIncidents(6)
            }
        </Carousel>
      ) : (
        <React.Fragment>{picture}</React.Fragment>
      )}
    </React.Fragment>
  );

  const content = (
    <Page className={classes.root} title="Service Health Dashboard">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={8} md={8} sm={12} xs={12} className={classes.gridItem}>
            {inIncident ? (
              <Grid
                container
                spacing={3}
                direction="column"
                justify="space-between"
              >
                <Grid item className={classes.gridItem}>
                  <Segment services={incidentServices} hexsize={hexsize} />
                </Grid>
                <Grid item className={classes.gridItem}>
                  <IncidentOrUptime services={incidentServices} />
                </Grid>
              </Grid>
            ) : (
              <Grid item className={classes.gridItem}>
                <Segment services={incidentServices} hexsize={hexsize} />
              </Grid>
            )}
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Grid
              container
              spacing={3}
              direction="column"
              justify="space-between"
            >
              <Grid
                item
                className={clsx(classes.currentIncidents, classes.gridItem)}
              >
                <StandardCard
                  cardContent={cardContent}
                  title="Current Incidents"
                />
              </Grid>
              <Grid item className={classes.gridItem}>
                <IncidentOverviewSmall
                  services={incidentServices}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );

  return (
    <React.Fragment>{content}</React.Fragment>
  )
};

export default ServiceRegisterView;
