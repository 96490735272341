/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      cognitoId
      email
      name
      displayName
      firstName
      surname
      jobTitle
      officeLocation
      roles
      photo
      createdAt
      updatedAt
      lastSignIn
      lastOnline
      online
      m2mUser
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      cognitoId
      email
      name
      displayName
      firstName
      surname
      jobTitle
      officeLocation
      roles
      photo
      createdAt
      updatedAt
      lastSignIn
      lastOnline
      online
      m2mUser
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      cognitoId
      email
      name
      displayName
      firstName
      surname
      jobTitle
      officeLocation
      roles
      photo
      createdAt
      updatedAt
      lastSignIn
      lastOnline
      online
      m2mUser
    }
  }
`;
export const onCreateRiskImpactCalculator = /* GraphQL */ `
  subscription OnCreateRiskImpactCalculator {
    onCreateRiskImpactCalculator {
      id
      createdAt
      updatedAt
      impactNumberValue
      riskNumberValue
      riskLevel
      impactLevel
      approvalLevel
      changeNumber
      riskQuestions
      impactQuestions
      user {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      owner
      userEmail
      submitted
    }
  }
`;
export const onUpdateRiskImpactCalculator = /* GraphQL */ `
  subscription OnUpdateRiskImpactCalculator {
    onUpdateRiskImpactCalculator {
      id
      createdAt
      updatedAt
      impactNumberValue
      riskNumberValue
      riskLevel
      impactLevel
      approvalLevel
      changeNumber
      riskQuestions
      impactQuestions
      user {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      owner
      userEmail
      submitted
    }
  }
`;
export const onDeleteRiskImpactCalculator = /* GraphQL */ `
  subscription OnDeleteRiskImpactCalculator {
    onDeleteRiskImpactCalculator {
      id
      createdAt
      updatedAt
      impactNumberValue
      riskNumberValue
      riskLevel
      impactLevel
      approvalLevel
      changeNumber
      riskQuestions
      impactQuestions
      user {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      owner
      userEmail
      submitted
    }
  }
`;
export const onCreateWeeklyReport = /* GraphQL */ `
  subscription OnCreateWeeklyReport {
    onCreateWeeklyReport {
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateWeeklyReport = /* GraphQL */ `
  subscription OnUpdateWeeklyReport {
    onUpdateWeeklyReport {
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteWeeklyReport = /* GraphQL */ `
  subscription OnDeleteWeeklyReport {
    onDeleteWeeklyReport {
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateUpdate = /* GraphQL */ `
  subscription OnCreateUpdate {
    onCreateUpdate {
      user {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      id
      region
      service
      wwwContent
      wdgwContent
      pfnwContent
      segment
      wwwIsHighlight
      createdAt
      owner
      updatedAt
    }
  }
`;
export const onUpdateUpdate = /* GraphQL */ `
  subscription OnUpdateUpdate {
    onUpdateUpdate {
      user {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      id
      region
      service
      wwwContent
      wdgwContent
      pfnwContent
      segment
      wwwIsHighlight
      createdAt
      owner
      updatedAt
    }
  }
`;
export const onDeleteUpdate = /* GraphQL */ `
  subscription OnDeleteUpdate {
    onDeleteUpdate {
      user {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      id
      region
      service
      wwwContent
      wdgwContent
      pfnwContent
      segment
      wwwIsHighlight
      createdAt
      owner
      updatedAt
    }
  }
`;
export const onCreateWeeklyReportUpdate = /* GraphQL */ `
  subscription OnCreateWeeklyReportUpdate {
    onCreateWeeklyReportUpdate {
      user {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      id
      region
      service
      content
      highlight
      updateType
      segment
      reportArea
      subSegment
      approved
      createdAt
      owner
      updatedAt
    }
  }
`;
export const onUpdateWeeklyReportUpdate = /* GraphQL */ `
  subscription OnUpdateWeeklyReportUpdate {
    onUpdateWeeklyReportUpdate {
      user {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      id
      region
      service
      content
      highlight
      updateType
      segment
      reportArea
      subSegment
      approved
      createdAt
      owner
      updatedAt
    }
  }
`;
export const onDeleteWeeklyReportUpdate = /* GraphQL */ `
  subscription OnDeleteWeeklyReportUpdate {
    onDeleteWeeklyReportUpdate {
      user {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      id
      region
      service
      content
      highlight
      updateType
      segment
      reportArea
      subSegment
      approved
      createdAt
      owner
      updatedAt
    }
  }
`;
export const onCreateHighlight = /* GraphQL */ `
  subscription OnCreateHighlight {
    onCreateHighlight {
      user {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      id
      segment
      createdAt
      updatedAt
      content
      service
      serviceId
      owner
    }
  }
`;
export const onUpdateHighlight = /* GraphQL */ `
  subscription OnUpdateHighlight {
    onUpdateHighlight {
      user {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      id
      segment
      createdAt
      updatedAt
      content
      service
      serviceId
      owner
    }
  }
`;
export const onDeleteHighlight = /* GraphQL */ `
  subscription OnDeleteHighlight {
    onDeleteHighlight {
      user {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      id
      segment
      createdAt
      updatedAt
      content
      service
      serviceId
      owner
    }
  }
`;
