import React, { useEffect, useRef, useState, Fragment } from "react";
import clsx from "clsx";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Container,
  makeStyles,
  Avatar,
  ListItemAvatar,
  Typography,
  useTheme,
} from "@material-ui/core";
import Page from "../../../components/Pages/Page";
import useServices from "../../../hooks/useServiceRegister";
import DashboardDetailedIncidentCard from "../../../components/Cards/DashboardDetailedIncidentCard";
import StandardCard from "../../../components/Cards/StandardCard";
import IncidentOverviewLarge from "./IncidentOverviewLarge";
import IncidentOrUptime from "./IncidentOrUptime";
import DarkLogo from "../../../media/logos/logo512-green.png";
import LightLogo from "../../../media/logos/logo512-black.png";
import { THEMES } from "../../../constants";
import { DoneRounded } from "@material-ui/icons";
import { API, graphqlOperation } from "aws-amplify";
import { realTimeIncidents } from "../../../graphql/queries";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  primary: {
    // light: will be calculated from palette.primary.main,
    main: "#ff4400",
    // dark: will be calculated from palette.primary.main,
    // contrastText: will be calculated to contrast with palette.primary.main
  },
  secondary: {
    light: "#0066ff",
    main: "#0044ff",
    // dark: will be calculated from palette.secondary.main,
    contrastText: "#ffcc00",
  },
  cardsIncident: {
    padding: theme.spacing(2),
    //height: "80%",
    marginBottom: 10,
    display: "flex",
    flexDirection: "column",
    //height: '20vw',
    minHeight: "20vw",
  },
  boxLogo: {
    marginLeft: "auto",
    marginRight: "auto",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  timeCard: {
    height: "20%",
  },
  gridItem: {
    display: "grid",
  },
  listItemAvatarGreen: {
    backgroundColor: theme.palette.success.main,
    marginRight: "10px",
  },
  currentIncidents: {
    display: "grid",
    [theme.breakpoints.up("md")]: {
      minHeight: "350px",
    },
  },
}));

const ServiceRegisterView = () => {
  const classes = useStyles();
  const { services } = useServices();
  const servicesRet = services;
  const [inIncident, setInIncident] = useState(false);
  const [incidentServices, SetIncidentServices] = useState(servicesRet);
  const [unresolvedIncidents, setUnresolvedIncidents] = useState([]);

  const getServices = (serviceArray) => {
    const services = serviceArray?.map((service) => {
      const incidentId = unresolvedIncidents?.filter((incident) => {
        var serviceIncident;
        if (service.Id === incident.serviceRegisterId) {
          serviceIncident = incident;
        }
        return serviceIncident;
      });
      if (service["Incident"] && incidentId.length === 0) {
        var newService = {
          ...service,
          classification: "Operational",
        };
        delete newService?.Incident
        return newService;
      }
      if (incidentId.length > 0) {
        var newService = {
          ...service,
          classification: incidentId[0].classification,
          Incident: {
            ...incidentId[0],
          },
        };
        return newService;
      }
      var newService = {
        ...service,
        classification: "Operational",
      };
      return newService;
    });

    const order = { Critical: 1, Major: 2, Minor: 3, FYI: 4, default: 1000 }
    const sortedServices = services?.sort(function (a, b) {
      var x = a?.classification; var y = b?.classification;
      return (order[x] || order.default) - (order[y] || order.default);
    });

    return sortedServices;
  };

  var incidentsInRealTime;

  const fetchData = async () => {
    incidentsInRealTime = await API.graphql(
      graphqlOperation(realTimeIncidents)
    );
    //console.log('realtime', incidentsInRealTime)
    if (incidentsInRealTime.data.realTimeIncidents !== null) {
      //console.log(incidentsInRealTime);
      setUnresolvedIncidents(() => [
        ...incidentsInRealTime.data.realTimeIncidents,
      ]);
    }
  };

  const fetchDataPeriodically = async () => {
    const newIncidentArray = await API.graphql(
      graphqlOperation(realTimeIncidents)
    );
    if (newIncidentArray.data.realTimeIncidents !== null) {
      if (newIncidentArray.data.realTimeIncidents.length != incidentsInRealTime.data.realTimeIncidents.length) {
        setUnresolvedIncidents(() => [...newIncidentArray.data.realTimeIncidents]);
        incidentsInRealTime.data.realTimeIncidents.push(...newIncidentArray.data.realTimeIncidents);
      }
    }
  }


  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchDataPeriodically();
    }, 10000);
  }, []);

  useEffect(() => {
    SetIncidentServices(getServices(servicesRet));
  }, [servicesRet]);

  useEffect(() => {
    //console.log('unresolvedIncidents', unresolvedIncidents)
    SetIncidentServices(getServices(incidentServices));
  }, [unresolvedIncidents]);

  useEffect(() => {
    if (
      incidentServices?.filter(
        (x) =>
          x?.Incident?.classification === "Minor" ||
          x?.Incident?.classification === "Major" ||
          x?.Incident?.classification === "Critical"
      ).length > 0
    ) {
      setInIncident(true);
    }
    else {
      setInIncident(false);
    }
  }, [incidentServices]);

  const picture = (
    <Grid container spacing={3}>
      <Box p={2} className={classes.boxLogo}>
        <img
          src={useTheme().name === THEMES.LIGHT ? LightLogo : DarkLogo}
          alt="Sage Cloud Ops Portal"
          width="50%"
        />
      </Box>
      <Box p={2} className={classes.boxLogo}>
        <Avatar className={classes.listItemAvatarGreen}>
          <DoneRounded />
        </Avatar>
        <Typography variant="h3">All Services Operational</Typography>
      </Box>
    </Grid>
  );

  const cardContent = (
    <React.Fragment>
      {inIncident ? (
        <Grid container spacing={3}>
          {incidentServices?.map((individualService) => {
            if (individualService.Incident?.classification) {
              return (
                <Grid
                  item
                  className={classes.gridItem}
                >
                  <DashboardDetailedIncidentCard
                    service={individualService.ServiceAssetName}
                    segmentLead={individualService.LiveServicesManager}
                    stats={individualService.Incident?.classification}
                    className={classes.card}
                  />
                </Grid>
              );
            }
          })}
        </Grid>
      ) : (
        <React.Fragment>{picture}</React.Fragment>
      )}
    </React.Fragment>
  );

  const content = (
    <Page className={classes.root} title="Current Incidents Dashboard">
      <Container maxWidth={true}>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.gridItem}>
            <Typography variant="h3" color="textPrimary" align="left">
              Current Open Incidents
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <IncidentOverviewLarge
              services={incidentServices}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );

  return (
    <React.Fragment>{content}</React.Fragment>
  )
};

export default ServiceRegisterView;
