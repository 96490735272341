/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      cognitoId
      email
      name
      displayName
      firstName
      surname
      jobTitle
      officeLocation
      roles
      photo
      createdAt
      updatedAt
      lastSignIn
      lastOnline
      online
      m2mUser
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      cognitoId
      email
      name
      displayName
      firstName
      surname
      jobTitle
      officeLocation
      roles
      photo
      createdAt
      updatedAt
      lastSignIn
      lastOnline
      online
      m2mUser
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      cognitoId
      email
      name
      displayName
      firstName
      surname
      jobTitle
      officeLocation
      roles
      photo
      createdAt
      updatedAt
      lastSignIn
      lastOnline
      online
      m2mUser
    }
  }
`;
export const createRiskImpactCalculator = /* GraphQL */ `
  mutation CreateRiskImpactCalculator(
    $input: CreateRiskImpactCalculatorInput!
    $condition: ModelRiskImpactCalculatorConditionInput
  ) {
    createRiskImpactCalculator(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      impactNumberValue
      riskNumberValue
      riskLevel
      impactLevel
      approvalLevel
      changeNumber
      riskQuestions
      impactQuestions
      user {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      owner
      userEmail
      submitted
    }
  }
`;
export const updateRiskImpactCalculator = /* GraphQL */ `
  mutation UpdateRiskImpactCalculator(
    $input: UpdateRiskImpactCalculatorInput!
    $condition: ModelRiskImpactCalculatorConditionInput
  ) {
    updateRiskImpactCalculator(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      impactNumberValue
      riskNumberValue
      riskLevel
      impactLevel
      approvalLevel
      changeNumber
      riskQuestions
      impactQuestions
      user {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      owner
      userEmail
      submitted
    }
  }
`;
export const deleteRiskImpactCalculator = /* GraphQL */ `
  mutation DeleteRiskImpactCalculator(
    $input: DeleteRiskImpactCalculatorInput!
    $condition: ModelRiskImpactCalculatorConditionInput
  ) {
    deleteRiskImpactCalculator(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      impactNumberValue
      riskNumberValue
      riskLevel
      impactLevel
      approvalLevel
      changeNumber
      riskQuestions
      impactQuestions
      user {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      owner
      userEmail
      submitted
    }
  }
`;
export const createWeeklyReport = /* GraphQL */ `
  mutation CreateWeeklyReport(
    $input: CreateWeeklyReportInput!
    $condition: ModelWeeklyReportConditionInput
  ) {
    createWeeklyReport(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateWeeklyReport = /* GraphQL */ `
  mutation UpdateWeeklyReport(
    $input: UpdateWeeklyReportInput!
    $condition: ModelWeeklyReportConditionInput
  ) {
    updateWeeklyReport(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteWeeklyReport = /* GraphQL */ `
  mutation DeleteWeeklyReport(
    $input: DeleteWeeklyReportInput!
    $condition: ModelWeeklyReportConditionInput
  ) {
    deleteWeeklyReport(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createUpdate = /* GraphQL */ `
  mutation CreateUpdate(
    $input: CreateUpdateInput!
    $condition: ModelUpdateConditionInput
  ) {
    createUpdate(input: $input, condition: $condition) {
      user {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      id
      region
      service
      wwwContent
      wdgwContent
      pfnwContent
      segment
      wwwIsHighlight
      createdAt
      owner
      updatedAt
    }
  }
`;
export const updateUpdate = /* GraphQL */ `
  mutation UpdateUpdate(
    $input: UpdateUpdateInput!
    $condition: ModelUpdateConditionInput
  ) {
    updateUpdate(input: $input, condition: $condition) {
      user {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      id
      region
      service
      wwwContent
      wdgwContent
      pfnwContent
      segment
      wwwIsHighlight
      createdAt
      owner
      updatedAt
    }
  }
`;
export const deleteUpdate = /* GraphQL */ `
  mutation DeleteUpdate(
    $input: DeleteUpdateInput!
    $condition: ModelUpdateConditionInput
  ) {
    deleteUpdate(input: $input, condition: $condition) {
      user {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      id
      region
      service
      wwwContent
      wdgwContent
      pfnwContent
      segment
      wwwIsHighlight
      createdAt
      owner
      updatedAt
    }
  }
`;
export const createWeeklyReportUpdate = /* GraphQL */ `
  mutation CreateWeeklyReportUpdate(
    $input: CreateWeeklyReportUpdateInput!
    $condition: ModelWeeklyReportUpdateConditionInput
  ) {
    createWeeklyReportUpdate(input: $input, condition: $condition) {
      user {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      id
      region
      service
      content
      highlight
      updateType
      segment
      reportArea
      subSegment
      approved
      createdAt
      owner
      updatedAt
    }
  }
`;
export const updateWeeklyReportUpdate = /* GraphQL */ `
  mutation UpdateWeeklyReportUpdate(
    $input: UpdateWeeklyReportUpdateInput!
    $condition: ModelWeeklyReportUpdateConditionInput
  ) {
    updateWeeklyReportUpdate(input: $input, condition: $condition) {
      user {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      id
      region
      service
      content
      highlight
      updateType
      segment
      reportArea
      subSegment
      approved
      createdAt
      owner
      updatedAt
    }
  }
`;
export const deleteWeeklyReportUpdate = /* GraphQL */ `
  mutation DeleteWeeklyReportUpdate(
    $input: DeleteWeeklyReportUpdateInput!
    $condition: ModelWeeklyReportUpdateConditionInput
  ) {
    deleteWeeklyReportUpdate(input: $input, condition: $condition) {
      user {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      id
      region
      service
      content
      highlight
      updateType
      segment
      reportArea
      subSegment
      approved
      createdAt
      owner
      updatedAt
    }
  }
`;
export const createHighlight = /* GraphQL */ `
  mutation CreateHighlight(
    $input: CreateHighlightInput!
    $condition: ModelHighlightConditionInput
  ) {
    createHighlight(input: $input, condition: $condition) {
      user {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      id
      segment
      createdAt
      updatedAt
      content
      service
      serviceId
      owner
    }
  }
`;
export const updateHighlight = /* GraphQL */ `
  mutation UpdateHighlight(
    $input: UpdateHighlightInput!
    $condition: ModelHighlightConditionInput
  ) {
    updateHighlight(input: $input, condition: $condition) {
      user {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      id
      segment
      createdAt
      updatedAt
      content
      service
      serviceId
      owner
    }
  }
`;
export const deleteHighlight = /* GraphQL */ `
  mutation DeleteHighlight(
    $input: DeleteHighlightInput!
    $condition: ModelHighlightConditionInput
  ) {
    deleteHighlight(input: $input, condition: $condition) {
      user {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      id
      segment
      createdAt
      updatedAt
      content
      service
      serviceId
      owner
    }
  }
`;
