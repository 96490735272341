import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  useTheme,
  Avatar,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import useIsMountedRef from "../../../../hooks/useIsMountedRef";
import "../../../../App.css";
import Clock from "../../../../components/DashboardItems/Clock";
import DashboardDetailedIncidentCard from "../../../../components/Cards/DashboardDetailedIncidentCard";
import StandardCard from "../../../../components/Cards/StandardCard";
import Carousel from 'react-material-ui-carousel';
import DarkLogo from "../../../../media/logos/logo512-green.png";
import LightLogo from "../../../../media/logos/logo512-black.png";
import { THEMES } from "../../../../constants";
import { DoneRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    textAlign: "center",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(3, 2),
    "&:not(:last-of-type)": {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
  incidentAnimate: {
    overflow: 'hidden',
  },
  inner: {
    whiteSpace: 'nowrap',
    transition: 'transform 0.3s',
  },
  incidentItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxLogo: {
    marginLeft: "auto",
    marginRight: "auto",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  listItemAvatarGreen: {
    backgroundColor: theme.palette.success.main,
    marginRight: "10px",
  },
}));

const IncidentOverviewSmall = ({ className, services, ...rest }) => {
  const classes = useStyles();
  const [overviewCards, setOverviewCards] = useState([]);
  const [currentCard, setCurrentCard] = useState();
  const [currentCardInt, setCurrentCardInt] = useState('0');
  const [firstCard, setFirstCard] = useState(true);

  const getOverviewCards = (services, size) => {
    const incidentServices = services?.filter((service) => {
      if (service?.Incident?.classification) {
        return true;
      }
      return false;
    });
    const cards = incidentServices?.map((service) => {
      return (
        <Grid item xs={12} className={classes.gridItem}>
          <DashboardDetailedIncidentCard
            service={service}
            className={classes.card}
          />
        </Grid>
      );
    });

    var displayCardsArray = []
    for (var i = 0; i < cards?.length; i += size) {
      const displayCardSection = (
        <Grid container spacing={3}>
          {cards.slice(i, i + size)}
        </Grid>
      )
      displayCardsArray.push(displayCardSection);
    }
    return displayCardsArray
  };

  const IncidentItem = ({ children, width }) => {
    return (
      <React.Fragment className={classes.incidentAnimate} style={{ width: width }}>
        {children}
      </React.Fragment>
    );
  };

  useEffect(() => {
    setOverviewCards(getOverviewCards(services, 6));
  }, [services]);

  const picture = (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box p={2} className={classes.boxLogo}>
          <img
            src={useTheme().name === THEMES.LIGHT ? LightLogo : DarkLogo}
            alt="Sage Cloud Ops Portal"
            width="20%"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} className={classes.boxLogo}>
          <Avatar className={classes.listItemAvatarGreen}>
            <DoneRounded />
          </Avatar>
          <Typography variant="h3">There are currently no running incidents</Typography>
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <React.Fragment>
      {overviewCards.length !== 0 ?
        <Carousel
          animation='slide'
          interval='15000'
          duration='3000'
          className={classes.carousel}
        >
          {
            getOverviewCards(services, 3)
          }
        </Carousel>
        :
        <React.Fragment>{picture}</React.Fragment>
      }
    </React.Fragment >
  );
};

IncidentOverviewSmall.propTypes = {
  className: PropTypes.string,
};

export default IncidentOverviewSmall;
