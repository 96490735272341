/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import { useLocation, matchPath } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  List,
  Button,
  Grid,
  ListSubheader,
  Typography,
  makeStyles,
  useTheme,
  Paper,
} from "@material-ui/core";
import useAuth from "../../../hooks/useAuth";
import NavItem from "./NavItem";
import { THEMES } from "../../../constants";
import { ReactComponent as DarkLogo } from "../../../media/logos/logo-green.svg";
import { ReactComponent as LightLogo } from "../../../media/logos/logo-black.svg";
import SvgIcon from "@material-ui/core/SvgIcon";
import {
  DashboardRounded,
  BarChartRounded,
  PostAddRounded,
  AppsRounded,
  PeopleRounded,
  ErrorRounded,
  HomeRounded,
  DynamicFeedRounded,
  CalendarTodayRounded,
  AssignmentRounded,
  DescriptionRounded,
  ChangeCircleRounded,
  FindInPageRounded
} from "@mui/icons-material";
import PermissionsGateContext from "../../../contexts/PermissionsGateContext";
import { SCOPES } from "../../../permissionMaps";

const sections = [
  {
    subheader: "FOR YOU",
    items: [
      {
        title: "My Dashboard",
        icon: HomeRounded,
        path: "/home",
      },
      {
        title: "All Applications",
        icon: AppsRounded,
        path: "/apps",
      },
    ],
  },
  // {
  //   subheader: "GENERAL",
  //   items: [
  //     // {
  //     //   title: "Monthly Metrics",
  //     //   icon: BarChartRounded,
  //     //   href: "/reports/metrics",
  //     // },
  //     {
  //       title: "Weekly Report",
  //       icon: DescriptionRounded,
  //       href: "/reports/weeklyReport",
  //       items: [
  //         {
  //           title: "Overview",
  //           href: "/reports/weeklyReport",
  //         },
  //         {
  //           title: "Add Updates",
  //           href: "/reports/weeklyReport/updateForm",
  //         },
  //         {
  //           title: "View Updates",
  //           href: "/reports/weeklyReport/viewUpdates",
  //         },
  //         {
  //           title: "Generate Reports",
  //           href: "/reports/weeklyReport/generateReport",
  //         },
  //         {
  //           title: "View Reports",
  //           href: "/reports/weeklyReport/listReports",
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    subheader: "GOVERNANCE",
    scopes: [SCOPES.restrictedCanView],
    items: [
      {
        title: "Service Register",
        icon: AssignmentRounded,
        path: "/governance/service-register",
        scopes: [SCOPES.cloudopsCanView, SCOPES.securityCanView, SCOPES.serviceRegisterCanView],
        items: [
          {
            title: "Add Service",
            path: "/governance/service-register/add",
            scopes: [SCOPES.cloudopsCanCreate, SCOPES.serviceRegisterCanCreate],
          },
          {
            title: "Quick View",
            path: "/governance/service-register/view",
          },
          {
            title: "Detailed View",
            path: "/governance/service-register/viewAll",
          },
        ],
      },
      {
        title: "Dashboards",
        icon: DashboardRounded,
        scopes: [SCOPES.restrictedCanView],
        items: [
          {
            title: "Availability",
            href: 'https://app.powerbi.com/Redirect?action=OpenApp&appId=f58364c4-34ae-4b5f-9717-c3f321a2803e&ctid=3e32dd7c-41f6-492d-a1a3-c58eb02cf4f8',
          },
          {
            title: "Azure Usage	",
            href: 'https://app.powerbi.com/links/tIi6gZ2X3k?ctid=3e32dd7c-41f6-492d-a1a3-c58eb02cf4f8&pbi_source=linkShare',
          },
          {
            title: "Incidents",
            href: 'https://sage.service-now.com/nav_to.do?uri=%2F$pa_dashboard.do%3Fsysparm_dashboard%3D6cff4fc61b0d30143b7921f0b24bcbe1',
          },
          {
            title: "People",
            href: 'https://app.powerbi.com/links/dBi-oVLoWO?ctid=3e32dd7c-41f6-492d-a1a3-c58eb02cf4f8&pbi_source=linkShare',
          },
          {
            title: "Risk",
            href: 'https://app.powerbi.com/links/RhbHhR6ev8?ctid=3e32dd7c-41f6-492d-a1a3-c58eb02cf4f8&pbi_source=linkShare',
          },
        ],
      },
    ],
  },
  {
    subheader: "SERVICE CENTRE",
    scopes: [SCOPES.cloudopsCanView],
    items: [
      {
        title: "Incidents",
        icon: ErrorRounded,
        scopes: [SCOPES.cloudopsCanView],
        items: [
          {
            title: "Overview",
            path: "/service-centre/incidents-overview",
          },
          // {
          //   title: "Start Incident",
          //   href: "/service-centre/incindetStart",
          // },
        ],
      },
      {
        title: "Change Management",
        icon: ChangeCircleRounded,
        scopes: [SCOPES.cloudopsCanView],
        items: [
          // {
          //   title: "Overview",
          //   href: "/service-centre/changeManagementOverview",
          // },
          // {
          //   title: "RFC Approval",
          //   href: "/service-centre/rfcApproval",
          // },
          {
            title: "Risk Impact Calculator",
            path: "/service-centre/risk-impact-calculator",
          },
        ],
      },
      {
        title: "Audit Logs",
        icon: FindInPageRounded,
        scopes: [SCOPES.auditLogsCanView],
        items: [
          {
            title: "Risk Impact Calculator",
            path: "/service-centre/risk-impact-calculator-audit-log",
          },
        ],
      },
    ],
  },
  {
    subheader: "EXECUTIVE DASHBOARDS",
    scopes: [SCOPES.cloudopsCanView, SCOPES.dashboardCanView],
    items: [
      {
        title: "Dashboards",
        icon: DashboardRounded,
        path: "/executive-dashboards/service-health",
        scopes: [SCOPES.cloudopsCanView, SCOPES.dashboardCanView],
        items: [
          {
            title: "Service Health",
            path: "/executive-dashboards/service-health",
          },
          {
            title: "Current Incidents",
            path: "/executive-dashboards/current-incidents",
          },
        ],
      },
    ],
  },
  // {
  //   subheader: "UTILITIES",
  //   icon: CalendarTodayRounded,
  //   items: [
  //     {
  //       title: "Team Calendars",
  //       href: "/utilities/teamCalendars",
  //     },
  //     {
  //       title: "Profile Page",
  //       href: "/utilities/profilePage",
  //     },
  //   ],
  // },
  // {
  //   subheader: "Exec views",
  //   items: [
  //     {
  //       title: "Walid dashboard",
  //       href: "/execViews/cpoDashboard"
  //     }
  //   ]
  // }
  {
    subheader: "ADMIN",
    scopes: [SCOPES.adminAll],
    items: [
      {
        title: "Users and Roles",
        icon: PeopleRounded,
        items: [
          {
            title: "Overview",
            path: "/admin/users-and-roles",
          },
          {
            title: "Add User",
            path: "/admin/users-and-roles/add",
          },
          {
            title: "View Users",
            path: "/admin/users-and-roles/view",
          },
        ],
      },
    ],
  },
];

function renderNavItems({ items, pathname, isExpandAll, depth = 0, sectionScopes }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, isExpandAll, depth, sectionScopes }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, isExpandAll, item, depth, sectionScopes }) {
  const key = item.title + depth;

  if (item.items) {
    // const open = matchPath(pathname, {
    //   path: item.href,
    //   exact: false,
    // });

    //const open = isExpandAll

    /*
    Item contains all of the top-level Nav bar items. We need to check if 
    title === Service Register as we will not render this nav item unless
    the currently authenticated user is part of groups === 'Managers'
    */

    acc.push(
      <PermissionsGateContext
        hideComponent={true}
        scopes={item.scopes || sectionScopes}>
        <NavItem
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={isExpandAll}
          title={item.title}
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items,
            isExpandAll: isExpandAll
          })}
        </NavItem>
      </PermissionsGateContext>
    );
  } else {
    acc.push(
      <PermissionsGateContext
        hideComponent={true}
        scopes={item.scopes || sectionScopes}>
        <NavItem
          depth={depth}
          href={item.href}
          path={item.path}
          icon={item.icon}
          info={item.info}
          key={key}
          title={item.title}
        />
      </PermissionsGateContext>
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 260,
    height: 'calc(100% - 41px)'
  },
  desktopDrawer: {
    width: 260,
    height: 'calc(100% - 41px)',
    border: 'none',
    backgroundColor: theme.palette.background.paper,
    ...theme.name === THEMES.DARK ? {
      backgroundColor: theme.palette.background.black95,
    } : {},
  },
  username: {
    margin: 'auto',
    width: '70%',
    padding: '0px 10px 10px 10px'
  },
  bottomPush: {
    position: "fixed",
    bottom: 0,
    textAlign: "center",
    paddingBottom: 5,
    backgroundColor: theme.palette.background.paper,
    ...theme.name === THEMES.DARK ? {
      backgroundColor: theme.palette.background.black95,
    } : {},
    width: 260,
    // borderTopStyle: 'solid',
    // borderTopColor: theme.palette.text.secondary,
    // borderTopWidth: 0.5
  }
}));

const NavBar = ({ onMobileClose, openMobile, onNavClose, openNav }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();
  const [isExpandAll, setExpandAll] = useState(false);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    if (openNav && onNavClose) {
      onNavClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const getNavItems = (open) => {
    return (
      <React.Fragment>
        {sections.map((section) => (
          <PermissionsGateContext
            hideComponent={true}
            scopes={section.scopes || []}>
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
                isExpandAll: open,
                sectionScopes: section.scopes
              })}
            </List>
          </PermissionsGateContext>
        ))}
      </React.Fragment>
    )
  }

  const [navItems, setNavItems] = useState(getNavItems(isExpandAll));

  useEffect(() => {

    setNavItems(getNavItems(isExpandAll))

  }, [isExpandAll]);

  const content = (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} p={1}>
          <RouterLink to="/home">
            <Box display="flex" justifyContent="center" paddingBottom="0px" fontSize="4.5rem">
              <SvgIcon height="100%" width="100%" viewBox="200 -150 100 550" fontSize="inherit">
                {useTheme().name === THEMES.LIGHT ? <LightLogo /> : <DarkLogo />}
              </SvgIcon>
            </Box>
          </RouterLink>
        </Grid>
        <Grid item xs={12} p={1}>
          <PerfectScrollbar options={{ suppressScrollX: true }} style={{ 'height': '110%' }}>
            <Box p={2} paddingTop="0px">
              {navItems}
            </Box>
          </PerfectScrollbar>
        </Grid >
      </Grid>
    </React.Fragment>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
          <Grid item xs={12} p={2} className={classes.bottomPush}>
            <Button
              // className={classes.button}
              // activeClassName={classes.active}
              onClick={() => setExpandAll(!isExpandAll)}
            // style={style}
            >
              {/* {Icon && (
            <Icon
              className={classes.icon}
              size="20"
            />
          )} */}
              <span>
                {isExpandAll ? "Collapse All" : "Expand All"}
              </span>
              {/* {open ? <ExpandLessIcon /> : <ExpandMoreIcon />} */}
            </Button>
            <Typography
              variant="caption"
              display="block"
            >
              Sage © 2023
            </Typography>
            <Typography
              variant="caption"
              display="block"
            >
              v{process.env.REACT_APP_VERSION}
            </Typography>
          </Grid>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open={openNav}
          variant="persistent"
        >
          {content}
          <Grid item xs={12} p={2} className={classes.bottomPush}>
            <Grid item xs={12} p={2}>
              <Button
                // className={classes.button}
                // activeClassName={classes.active}
                onClick={() => setExpandAll(!isExpandAll)}
              // style={style}
              >
                {/* {Icon && (
            <Icon
              className={classes.icon}
              size="20"
            />
          )} */}
                <span>
                  {isExpandAll ? "Collapse All" : "Expand All"}
                </span>
                {/* {open ? <ExpandLessIcon /> : <ExpandMoreIcon />} */}
              </Button>
            </Grid>
            <Grid item xs={12} p={2}>
              <Button
                href='https://jira.sage.com/secure/CreateIssue.jspa?issuetype=1&pid=32701'
                target="_blank"
                size="small"
              >
                Report Bug
              </Button>
              <Button
                href='https://jira.sage.com/secure/CreateIssue.jspa?issuetype=7&pid=32701'
                target="_blank"
                size="small"
              >
                Request Feature
              </Button>
              <Typography
                variant="caption"
                display="block"
              >
                Sage © 2023
              </Typography>
              <RouterLink to="/release-notes" style={{ textDecoration: 'none' }}>
                <Button
                  href='https://jira.sage.com/secure/CreateIssue.jspa?issuetype=7&pid=32701'
                  target="_blank"
                  size="small"
                >
                  v{process.env.REACT_APP_VERSION}
                </Button>
              </RouterLink>
            </Grid>
          </Grid>
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  onNavClose: PropTypes.func,
  openNav: PropTypes.bool,
};

export default NavBar;
