import React, { useEffect, useRef, useState, Fragment } from "react";
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import {
  MenuRounded,
} from '@material-ui/icons'
import { THEMES } from '../../../constants';
import { ReactComponent as DarkLogo } from "../../../media/logos/logo-green.svg";
import { ReactComponent as LightLogo } from "../../../media/logos/logo-black.svg";
import Account from './Account';
import Search from './Search';
import DarkMode from './DarkMode';
import FullscreenMode from "./Fullscreen";

const TopBar = ({
  className,
  onMobileNavOpen,
  fullscreen,
  setFullscreen,
  ...rest
}) => {

  const useStyles = makeStyles((theme) => ({
    root: {
      zIndex: theme.zIndex.drawer + 100,
      ...theme.name === THEMES.LIGHT ? {
        backgroundColor: theme.palette.background.defaultTransparent,
        color: theme.palette.text.secondary
      } : {},
      ...theme.name === THEMES.DARK ? {
        backgroundColor: theme.palette.background.defaultTransparent,
        color: theme.palette.text.secondary
      } : {}
    },
    toolbar: {
      minHeight: 64,
      paddingLeft: '0.5%'
    }
  }
  ));

  const classes = useStyles();

  return (
    <AppBar
      elevation={0}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar
        className={classes.toolbar}
      >
        <RouterLink to="/home">
        <Box p={2} paddingBottom={0} paddingTop={0} display="flex" justifyContent="center" fontSize="3rem">
        <SvgIcon height="100%" width="100%" viewBox="200 -150 100 550" fontSize="inherit">
          {useTheme().name === THEMES.LIGHT ? <LightLogo /> : <DarkLogo />}
        </SvgIcon>
      </Box>
        </RouterLink>
        <Box
          ml={2}
          flexGrow={1}
        />
        <FullscreenMode setFullscreen={setFullscreen} fullscreen={fullscreen} />
        <DarkMode />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => { }
};

export default TopBar;