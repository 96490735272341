import { CardActions, CardHeader, Card, Grid, Typography, CardContent, makeStyles } from "@material-ui/core";
import * as React from "react";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {},
    card: {

        // Use flex layout with column direction for components in the card
        // (CardContent and CardActions)
        display: "flex",
        flexDirection: "column",

        // Justify the content so that CardContent will always be at the top of the card,
        // and CardActions will be at the bottom
        //justifyContent: "space-between",
        height: '100%'
    }
}));


const StandardCard = ({ cardContent, cardActions, title, elevation, className }) => {
    const classes = useStyles();

    return (
        <Card elevation={elevation ? elevation : 0} className={clsx(className, classes.card)}>
            {title ?
                <CardHeader
                    title={title}
                    titleTypographyProps={{ variant: "h3", color: "textPrimary" }}
                />
                :
                < React.Fragment />
            }
            <CardContent>
                {cardContent}
            </CardContent>
            {cardActions ?
                <CardActions>
                    {cardActions}
                </CardActions>
                :
                < React.Fragment />
            }
        </Card>
    )
}

export default StandardCard;