import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    "@global": {
      "*": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
      },
      html: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        height: "100%",
        width: "100%",
      },
      body: {
        height: "100%",
        width: "100%",
      },
      "#root": {
        height: "100%",
        width: "100%",
      },
      // '.m-sm-30': {
      //   margin: '30px',
      //   [theme.breakpoints.down('sm')]: {
      //     margin: '16px',
      //   },
      // },
      // '--sidenav-width': '260px',
      // '--topbar-height': '64px',
      // ".w-full": { width: "100%" },
      // ".card": { transition: "all 0.3s ease" },
      // ".items-center": { alignItems: "center" },
      // ".h1, h1": { fontSize: "2rem" },
      // ".h2, h2": { fontSize: "1.75rem" },
      // ".h3, h3": { fontSize: "1.5rem" },
      // ".h4, h4": { fontSize: "1.25rem" },
      // ".h5, h5": { fontSize: "1rem" },
      // '.bg-light-gray': { background: 'rgba(0, 0, 0, 0.01) !important' },
      // ".justify-between": { justifyContent: "space-between !important" },
      // ".justify-center": { justifyContent: "center" },
      // ".flex": { display: "flex" },
      // ".flex-grow": { flexGrow: "1" },
      // ".flex-column": { display: "flex", flexDirection: "column" },
      // ".text-white": { color: "rgba(255, 255, 255, 1) !important" },
      // ".text-13": { fontSize: "13px !important" },
      // '.text-40': { fontSize: '40px !important' },
      // ".h6, h6": { fontSize: "0.875rem" },
      // ".text-center": { textAlign: "center" },
      // ".relative": { position: "relative" },
      // ".bg-green": {
      //   backgroundColor: `${theme.palette.secondary.main} !important`,
      //   background: "#08ad6c !important",
      // },
      // ".hover-bg-secondary:hover": {
      //   background: `${theme.palette.secondary.main} !important`,
      //   color: "#ffffff",
      //   backgroundColor: `${theme.palette.secondary.main} !important`,
      //   fallbacks: [{ color: "white !important" }],
      // },
      // ".font-medium": { fontWeight: "500 !important" },
      // ".circle-44": { height: "44px !important", width: "44px !important" },
      ...generateShadows(theme),
      ...generateMarginPadding(0, 25, 0.25, "rem"),
      ...generateMarginPadding(1, 16, 1, "px"),
      ...generateMarginPadding(-25, -1, 0.25, "rem"),
      ...generateMarginPadding(-16, -1, 1, "px"),
      ...generateHeightWidth(),
    },
  })
);

const generateShadows = (theme) => {
  let classList = {}

  theme.shadows.forEach((shadow, ind) => {
    classList[`.elevation-z${ind}`] = {
      boxShadow: `${shadow} !important`,
    }
  })
}

const generateMarginPadding = (
  start = 0,
  end = 25,
  increament = 0.25,
  unit = "rem"
) => {
  let classList = {};
  for (let i = start; i <= end; i++) {
    classList[`.mb-${i}${unit === "px" ? "px" : ""}`] = {
      marginBottom: `${i * increament}${unit} !important`,
    };
    classList[`.px-${i}${unit === "px" ? "px" : ""}`] = {
      paddingLeft: `${i * increament}${unit} !important`,
      paddingRight: `${i * increament}${unit} !important`,
    };
    classList[`.p-${i}${unit === "px" ? "px" : ""}`] = {
      padding: `${i * increament}${unit} !important`,
    };
    classList[`.ml-${i}${unit === "px" ? "px" : ""}`] = {
      marginLeft: `${i * increament}${unit} !important`,
    };
    classList[`.mt-${i}${unit === 'px' ? 'px' : ''}`] = {
      marginTop: `${i * increament}${unit} !important`,
    }
    classList[`.m-${i}${unit === "px" ? "px" : ""}`] = {
      margin: `${i * increament}${unit} !important`,
    };
    classList[`.py-${i}${unit === "px" ? "px" : ""}`] = {
      paddingTop: `${i * increament}${unit} !important`,
      paddingBottom: `${i * increament}${unit} !important`,
    };
    classList[`.mr-${i}${unit === 'px' ? 'px' : ''}`] = {
      marginRight: `${i * increament}${unit} !important`,
    }
    classList[`.pt-${i}${unit === "px" ? "px" : ""}`] = {
      paddingTop: `${i * increament}${unit} !important`,
    };
  }

  return classList;
};

const generateHeightWidth = (
  start = 0,
  end = 400,
  increament = 4,
  unit = "px"
) => {
  let classList = {};

  for (let i = start; i <= end; i += increament) {
    classList[`.w-${i}`] = {
      width: `${i}${unit} !important`,
    };
    classList[`.min-w-${i}`] = {
      minWidth: `${i}${unit} !important`,
    };
    classList[`.max-w-${i}`] = {
      maxWidth: `${i}${unit} !important`,
    };
    classList[`.h-${i}`] = {
      height: `${i}${unit} !important`,
    };
    classList[`.min-h-${i}`] = {
      minHeight: `${i}${unit} !important`,
    };
    classList[`.max-h-${i}`] = {
      maxHeight: `${i}${unit} !important`,
    };
  }

  return classList;
};

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
