import React, { createRef } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
// import { create } from "jss";
// import rtl from "jss-rtl";
import MomentUtils from "@date-io/moment";
import { SnackbarProvider } from "notistack";
import { jssPreset, StylesProvider, ThemeProvider, Collapse, IconButton } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import GlobalStyles from "./components/GlobalStyles";
import ScrollReset from "./components/ScrollReset";
import { AuthProvider } from "./contexts/SSOContext";
import { ServiceProvider } from "./contexts/ServiceRegisterContext";
import { ServiceNowFormFieldsProvider } from "./contexts/ServiceNowFormFieldsContext";
import useSettings from "./hooks/useSettings";
import { createTheme } from "./theme";
import routes, { renderRoutes } from "./routes";
import { configureAmplify } from "./configureAmplify";
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  CloseRounded,
} from "@mui/icons-material";

require("dotenv").config();

configureAmplify();

// const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App = () => {
  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  const notistackRef = createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* <StylesProvider jss={jss}> */}
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider
            dense
            maxSnack={3}
            autoHideDuration={10000}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            ref={notistackRef}
            action={(key) => (
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={onClickDismiss(key)}
              >
                <CloseRounded fontSize="small" />
              </IconButton>
            )}
            TransitionComponent={Collapse}
          >
            <Router history={history}>
              <AuthProvider>
                <ServiceProvider>
                  <ServiceNowFormFieldsProvider>
                    <GlobalStyles />
                    <ScrollReset />
                    {renderRoutes(routes)}
                  </ServiceNowFormFieldsProvider>
                </ServiceProvider>
              </AuthProvider>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
        {/* </StylesProvider> */}
      </ThemeProvider>
    </>
  );
};

export default App;
