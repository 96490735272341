import React, { createContext, useEffect, useReducer } from "react";
import SplashScreen from "../components/SplashScreen";
import { API, Auth, Cache, graphqlOperation } from "aws-amplify";
import { getUser, initUser, getUserByEmail } from "../graphql/queries";
import { updateUser } from "../graphql/mutations";
import { onUpdateUser } from "../graphql/subscriptions";
import { useSnackbar } from "notistack";

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIALISE": {
      const { isAuthenticated, user } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user: user,
      };
    }
    case "REFRESH": {
      const { isAuthenticated, user } = action.payload;
      return {
        ...state,
        isAuthenticated: isAuthenticated,
        user: {
          ...state.user,
          ...user,
        }
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  loginWithSSO: () => { },
  login: () => { },
  logout: () => { },
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { enqueueSnackbar } = useSnackbar();

  const initialise = async () => {
    try {
      const isAuthenticated = await Auth.currentAuthenticatedUser();

      if (isAuthenticated) {
        const activeSession = await Auth.currentSession();

        var user;
        var email = (isAuthenticated.signInUserSession.idToken.payload.email).toLowerCase() || ""

        const req = await API.graphql(
          graphqlOperation(getUserByEmail, {
            email: email,
          })
        );
        user = req?.data?.getUserByEmail.items[0];

        var userInput

        const signInDate = new Date().toISOString();

        if (user?.cognitoId == null) {
          // Need to call the lambda resolver for initialiseUser
          const response = await API.graphql(graphqlOperation(initUser, { email: email, sub: isAuthenticated.signInUserSession.idToken.payload.sub, userId: user.id, userPoolId: process.env.REACT_APP_USERPOOL_ID, m2mUser: user?.m2mUser }))
          var userResponse = {
            ...user,
            ...JSON.parse(response.data.initUser),
          }

          userInput = {
            ...userResponse,
            lastSignIn: signInDate,
            lastOnline: signInDate,
            online: true
          }
          delete userInput.roles

          const modfiedUser = await API.graphql(graphqlOperation(updateUser, { input: userInput }));
          user = userResponse
        }
        else if (user !== null) {

          var userInput = {
            ...user,
            lastSignIn: signInDate,
            lastOnline: signInDate,
            online: true
          }
          delete userInput.roles

          const modfiedUser = await API.graphql(graphqlOperation(updateUser, { input: userInput }));
        }

        const token = activeSession.accessToken.jwtToken;

        dispatch({
          type: "INITIALISE",
          payload: {
            isAuthenticated: true,
            user: {
              ...user,
              token: token,
              lastSignIn: signInDate,
              lastOnline: signInDate,
              online: true
            },
          },
        });
      } else {
        dispatch({
          type: "INITIALISE",
          payload: {
            isAuthenticated,
            user: null,
          },
        });
        enqueueSnackbar("There was an error logging in. Please try again.", {
          variant: "error",
        });
      }
    } catch (err) {
      dispatch({
        type: "INITIALISE",
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  };

  useEffect(() => {
    initialise();

    const interval = setInterval(async () => {
      try {
        const isAuthenticated = await Auth.currentAuthenticatedUser();
        if (isAuthenticated) {
          const activeSession = await Auth.currentSession();

          var user;
          const req = await API.graphql(
            graphqlOperation(getUserByEmail, {
              email: (isAuthenticated.signInUserSession.idToken.payload.email).toLowerCase(),
            })
          );
          user = req?.data?.getUserByEmail.items[0];

          const token = activeSession.accessToken.jwtToken;

          const payload = {
            isAuthenticated: true,
            user: {
              ...user,
              token: token
            },
          }

          dispatch({
            type: "REFRESH",
            payload: {
              isAuthenticated: true,
              user: {
                ...user,
                token: token
              },
            },
          });
        }
        else {
          await logout()
        }
      } catch (err) {
        const signOutDate = new Date().toISOString();

        var userInput = {
          id: state.user?.id,
          lastOnline: signOutDate,
          online: false
        }

        const modfiedUser = await API.graphql(graphqlOperation(updateUser, { input: userInput }));

        dispatch({
          type: "INITIALISE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const getUpdate = API.graphql(graphqlOperation(onUpdateUser)).subscribe({
      next: data => {
        const initialise = async () => {
          try {
            const activeSession = await Auth.currentSession();
            const isAuthenticated = await Auth.currentAuthenticatedUser();
            const { value: { data: { onUpdateUser } } } = data
            if (onUpdateUser.cognitoId === isAuthenticated.signInUserSession.accessToken.payload.sub) {
              const req = await API.graphql(
                graphqlOperation(getUser, {
                  id: onUpdateUser.id,
                })
              );
              const user = req?.data?.getUser;

              dispatch({
                type: "REFRESH",
                payload: {
                  isAuthenticated: true,
                  user: {
                    ...user,
                  },
                },
              });
            }
          }
          catch (err) {
            console.error(err);
          }
        }
        initialise()
      }
    })
    return () => getUpdate.unsubscribe()
  }, [])

  const loginWithSSO = async (options) => {
    try {
      await Auth.federatedSignIn({ provider: "AzureAD" });
    } catch (error) {
      console.log("There was an error signing in to the app", error);
    }
  };

  const login = async (options) => {
    try {
      var user = await Auth.signIn(options.username, options.password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return {
          error: user.challengeName,
          login: false,
          user: user
        }
      }
      if (user) {
        initialise();
      }
      else {
        enqueueSnackbar("Could not log you in. Please try again.", {
          variant: "error",
        });
        return {
          error: "Could not log you in. Please try again.",
          login: false
        }
      }
      return { login: true }
    } catch (error) {
      enqueueSnackbar("Could not log you in. Please try again.", {
        variant: "error",
      });
      return {
        error: error,
        login: false
      }
    }
  };

  const firstTimePasswordReset = async (options) => {
    try {
      await Auth.completeNewPassword(
        options.user,           // the Cognito User Object
        options.newPassword,       // the new password
      )

      var newDetails = {
        username: options.user.challengeParam.userAttributes.email,           // the Cognito User Object
        password: options.newPassword
      }
      await login(newDetails)
    } catch (error) {
      enqueueSnackbar("Error: Could not change the password.", {
        variant: "error",
      });
    }
  };

  const logout = async () => {
    try {
      Cache.removeItem('prbs')
      sessionStorage.removeItem('loginRedirectPath')

      const lastOnline = new Date().toISOString();

      var userInput = {
        id: state.user?.id,
        lastOnline: lastOnline,
        online: false
      }

      const modfiedUser = await API.graphql(graphqlOperation(updateUser, { input: userInput }));

      await Auth.signOut();
    } catch (error) {
      console.log("There was an error signing out of the app", error);
    }

    dispatch({
      type: "LOGOUT",
    });
  };

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        firstTimePasswordReset,
        login,
        loginWithSSO,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
