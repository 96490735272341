import React, {
    useState,
    useRef,
    useEffect
} from 'react';
import {
    Badge,
    Box,
    Button,
    IconButton,
    Popover,
    SvgIcon,
    TextField,
    Tooltip,
    Typography,
    makeStyles,
    useTheme
} from '@material-ui/core';
import useSettings from '../../../hooks/useSettings';
import { THEMES } from '../../../constants';
import {
    Brightness2Rounded as LightModeIcon,
    WbSunnyRounded as DarkModeIcon,
} from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
    badge: {
        height: 10,
        width: 10,
        borderRadius: 5,
        marginTop: 10,
        marginRight: 5
    },
    popover: {
        width: 320,
        padding: theme.spacing(2)
    }
}));

const DarkMode = () => {
    const classes = useStyles();
    const ref = useRef(null);
    const { settings, saveSettings } = useSettings();

    const handleChange = (theme) => {
        saveSettings({theme: theme});
    };

    return (
        <>
            <Tooltip title={settings.theme === THEMES.LIGHT ? "Dark Mode" : "Light Mode"}>
                <IconButton
                    color="inherit"
                    onClick={settings.theme === THEMES.LIGHT ? () => handleChange(THEMES.DARK) : () => handleChange(THEMES.LIGHT)}
                    ref={ref}
                >
                    <SvgIcon fontSize="small">
                        {settings.theme === THEMES.LIGHT ? <LightModeIcon /> : <DarkModeIcon />}
                    </SvgIcon>
                </IconButton>
            </Tooltip>
        </>
    );
}

export default DarkMode;