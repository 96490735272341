import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import useIsMountedRef from "../../../../hooks/useIsMountedRef";
import Circle from "../../../../components/DashboardItems/Shapes/Circle";
import StandardCard from "../../../../components/Cards/StandardCard";

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    textAlign: "center",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    //padding: theme.spacing(3, 2),
    //"&:not(:last-of-type)": {
    //borderRight: `1px solid ${theme.palette.divider}`,
    //},
  },
  circle: {},
  circularText: {
    marginBottom: 10,
  },
  currentIncidentTitle: {
    justifyContent: 'flex-end',
    // marginTop: '-70px',
  },
  cardNumber: {
    borderColor: theme.palette.error.main,
    borderStyle: 'solid',
    borderWidth: 4,
    borderRadius: 24,
    // fontSize: '60px'
  },
  criticalIncidents: {
    borderColor: theme.palette.error.main,
    borderStyle: 'solid',
    borderWidth: 4,
    borderRadius: 48,
    // animation: `$criticalEffect 2500ms ${theme.transitions.easing.easeInOut}`,
    // animationIterationCount: 'infinite'
  },
  majorIncidents: {
    borderColor: theme.palette.warning.main,
    borderStyle: 'solid',
    borderWidth: 4,
    borderRadius: 48,
    // animation: `$majorEffect 2500ms ${theme.transitions.easing.easeInOut}`,
    // animationIterationCount: 'infinite'
  },
  minorIncidents: {
    borderColor: theme.palette.focus.main,
    borderStyle: 'solid',
    borderWidth: 4,
    maxWidth: 50,
    borderRadius: 48,
    // animation: `$minorEffect 2500ms ${theme.transitions.easing.easeInOut}`,
    // animationIterationCount: 'infinite'
  },
  fyiIncidents: {
    borderColor: theme.palette.info.main,
    borderStyle: 'solid',
    borderWidth: 4,
    borderRadius: 48,
    // animation: `$fyiEffect 2500ms ${theme.transitions.easing.easeInOut}`,
    // animationIterationCount: 'infinite'
  },
  "@keyframes criticalEffect": {
    "0%": {
      borderStyle: theme.palette.error.main,
    },
    "50%": {
      borderColor: 'transparent'
    },
    "100%": {
      borderColor: theme.palette.error.main,
    }
  },
  "@keyframes majorEffect": {
    "0%": {
      borderStyle: theme.palette.warning.main,
    },
    "50%": {
      borderColor: 'transparent'
    },
    "100%": {
      borderColor: theme.palette.warning.main,
    }
  },
  "@keyframes minorEffect": {
    "0%": {
      borderStyle: theme.palette.focus.main,
    },
    "50%": {
      borderColor: 'transparent'
    },
    "100%": {
      borderColor: theme.palette.focus.main,
    }
  },
  "@keyframes fyiEffect": {
    "0%": {
      borderStyle: theme.palette.info.main,
    },
    "50%": {
      borderColor: 'transparent'
    },
    "100%": {
      borderColor: theme.palette.info.main,
    }
  }
}));

const IncidentOrUptime = ({ className, services, ...rest }) => {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();

  const fyiIncidents = services?.filter(x => x?.Incident?.classification === 'FYI').length
  const minorIncidents = services?.filter(x => x?.Incident?.classification === 'Minor').length
  const majorIncidents = services?.filter(x => x?.Incident?.classification === 'Major').length
  const crticalIncidents = services?.filter(x => x?.Incident?.classification === 'Critical').length

  var fyiClass = null
  var minorClass = null
  var majorClass = null
  var criticalClass = null

  if (fyiIncidents > 0) {
    fyiClass = classes.fyiIncidents
  }

  if (minorIncidents > 0) {
    minorClass = classes.minorIncidents
  }

  if (majorIncidents > 0) {
    majorClass = classes.majorIncidents
  }
  if (crticalIncidents > 0) {
    criticalClass = classes.criticalIncidents
  }

  const cardFYI = (
    <React.Fragment>
      <Typography variant="h3" color="textPrimary" align="center">
        {minorIncidents}
      </Typography>
      <Box m={2}>
        <Typography variant="h4" color="textPrimary" align="center">
          {"FYI"}
        </Typography>
      </Box>
    </React.Fragment>
  )

  const cardMinor = (
    <React.Fragment>
      <Typography variant="h3" color="textPrimary" align="center">
        {minorIncidents}
      </Typography>
      <Box m={2}>
        <Typography variant="h4" color="textPrimary" align="center">
          {"Minor"}
        </Typography>
      </Box>
    </React.Fragment>
  )

  const cardMajor = (
    <React.Fragment>
      <Typography variant="h3" color="textPrimary" align="center">
        {majorIncidents}
      </Typography>
      <Box m={2}>
        <Typography variant="h4" color="textPrimary" align="center">
          {"Major"}
        </Typography>
      </Box>
    </React.Fragment>
  )

  const cardCritical = (
    <React.Fragment>
      <Typography variant="h3" color="textPrimary" align="center">
        {crticalIncidents}
      </Typography>
      <Box m={2}>
        <Typography variant="h4" color="textPrimary" align="center">
          {"Critical"}
        </Typography>
      </Box>
    </React.Fragment>
  )


  return (
    <Grid container className={classes.currentIncidentTitle}>
      {/* <Grid item md={4} s={4} xs={12} className={criticalClass}>
        <Typography variant="h3" color="textPrimary" align="left">
          Current Open Incidents
        </Typography>
      </Grid> */}
      <Grid item md={2} s={2} xs={12} className={criticalClass}>
        {cardCritical}
      </Grid>
      <Grid item md={2} s={2} xs={12} className={majorClass}>
        {cardMajor}
      </Grid>
      <Grid item md={2} s={2} xs={12} className={minorClass}>
        {cardMinor}
      </Grid>
      <Grid item md={2} s={2} xs={12} className={fyiClass}>
        {cardFYI}
      </Grid>
    </Grid>
  );
};

IncidentOrUptime.propTypes = {
  className: PropTypes.string,
};

export default IncidentOrUptime;
