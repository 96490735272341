import React, {
  useRef,
  useState,
  useEffect
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  Bell as BellIcon,
  Edit as EditIcon,
  MessageCircle as MessageIcon,
  Truck as TruckIcon
} from 'react-feather';
import useNotification from '../../../hooks/useNotifications'
import moment from 'moment'

const iconsMap = {
  edit: EditIcon,
  new_message: MessageIcon,
  item_shipped: TruckIcon
};

// Mock notifications for now
const notifications = [
  {
    id: '5e8883f1b51cc1956a5a1ec0',
    createdAt: moment()
      .subtract(2, 'hours')
      .toDate()
      .getTime(),
    description: 'Incident Management',
    title: 'Finish PRB23453794',
    type: 'edit'
  },
  {
    id: '5e8883f7ed1486d665d8be1e',
    createdAt: moment()
      .subtract(1, 'day')
      .toDate()
      .getTime(),
    description: 'Incident Management',
    title: 'Update secret',
    type: 'edit'
  },
];

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  iconBorder: {
    boxSizing: 'border-box',
    height: 36,
    width: 36,
    borderColor: theme.palette.actions.success,
    borderStyle: 'solid',
    borderWidth: 1,
    animation: `$avatarEffect 3000ms ${theme.transitions.easing.easeInOut}`,
    animationIterationCount: 'infinite'
  },
  "@keyframes avatarEffect": {
    "0%": {
      borderStyle: theme.palette.actions.success,
    },
    "50%": {
      borderColor: 'transparent'
    },
    "100%": {
      borderColor: theme.palette.actions.success,
    },
  },
  iconFill: {
    fill: theme.palette.actions.success,
    animation: `$fillEffect 3000ms ${theme.transitions.easing.easeInOut}`,
    animationIterationCount: 'infinite'
  },
  "@keyframes fillEffect": {
    "0%": {
      fill: theme.palette.actions.success,
    },
    "50%": {
      fill: 'transparent'
    },
    "100%": {
      fill: theme.palette.actions.success,
    }
  }
}));

const Notifications = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const {
    deleteNotification,
    clearNotifications,
    // When hooke dup to the context API will store notifications in here

    //notifications,
  } = useNotification()


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton
          color="inherit"
          ref={ref}
          onClick={handleOpen}
          className={classes.iconBorder}
        >
          <SvgIcon>
            <BellIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography
            variant="h5"
            color="textPrimary"
          >
            Notifications
          </Typography>
        </Box>
        {notifications.length === 0 ? (
          <Box p={2}>
            <Typography
              variant="h6"
              color="textPrimary"
            >
              There are no notifications
            </Typography>
          </Box>
        ) : (
          <>
            <List disablePadding>
              {notifications.map((notification) => {
                const Icon = iconsMap[notification.type];

                return (
                  <ListItem
                    component={RouterLink}
                    divider
                    key={notification.id}
                    to="#"
                  >
                    <ListItemAvatar>
                      <Avatar
                        className={classes.icon}
                      >
                        <SvgIcon fontSize="small">
                          <Icon />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={notification.title}
                      primaryTypographyProps={{ variant: 'subtitle2', color: 'textPrimary' }}
                      secondary={notification.description}
                    />
                  </ListItem>
                );
              })}
            </List>
            <Box
              p={1}
              display="flex"
              justifyContent="center"
            >
              <Button
                component={RouterLink}
                size="small"
                to="#"
              >
                Mark all as read
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
};

export default Notifications;
