import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import useIsMountedRef from "../../../../hooks/useIsMountedRef";
import "../../../../App.css";
import Clock from "../../../../components/DashboardItems/Clock";
import DashboardServiceOverviewSmallCard from "../../../../components/Cards/DashboardServiceOverviewSmallCard";
import Timezone from "../Timezone";
import StandardCard from "../../../../components/Cards/StandardCard";
import Carousel from 'react-material-ui-carousel'

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    textAlign: "center",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(3, 2),
    "&:not(:last-of-type)": {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
}));

export const IncidentItem = ({ children, width }) => {
  return (
    <div className="incident-item" style={{ width: width }}>
      {children}
    </div>
  );
};

const IncidentOverviewSmall = ({ className, services, ...rest }) => {
  const classes = useStyles();
  const [overviewCards, setOverviewCards] = useState([]);
  // const [currentCard, setCurrentCard] = useState();
  // const [currentCardInt, setCurrentCardInt] = useState('0');
  // const [firstCard, setFirstCard] = useState(true);

  const getOverviewCards = (services) => {
    const incidentServices = services?.filter((service) => {
      if (service?.Incident?.classification) {
        return true;
      }
      return false;
    });
    const cards = incidentServices?.map((service) => {
      //console.log("service", service);
      return (
        <DashboardServiceOverviewSmallCard
          service={service}
          className={classes.card}
        />
      );
    });
    return cards;
  };

  // const IncidentAnimate = ({ children }) => {
  //   const [activeIndex, setActiveIndex] = useState(0);

  //   const updateIndex = (newIndex) => {
  //     if (newIndex < 0) {
  //       newIndex = React.children.count(children) - 1;
  //     } else if (newIndex >= React.Children.count(children)) {
  //       newIndex = 0;
  //     }

  //     setActiveIndex(newIndex);
  //   };

  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       updateIndex(activeIndex + 1);
  //     }, 3000);

  //     return () => {
  //       if (interval) {
  //         clearInterval(interval);
  //       }
  //     };
  //   });

  //   return (
  //     <div className="incidentAnimate">
  //       <div
  //         className="inner"
  //         style={{ transform: `translateX(-${activeIndex * 100}%)` }}
  //       >
  //         {React.Children.map(children, (child, index) => {
  //           return React.cloneElement(child, { width: "100%" });
  //         })}
  //       </div>
  //     </div>
  //   );
  // };

  // useEffect(() => {
  //   setOverviewCards(getOverviewCards(services));
  // }, [services]);

  useEffect(() => {
    setOverviewCards(getOverviewCards(services));
  }, [services]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     var card = overviewCards[parseInt(currentCardInt)]
  //     if (parseInt(currentCardInt) >= overviewCards.length && !firstCard) {
  //       setCurrentCardInt('0');
  //       card = overviewCards[0]
  //       setCurrentCard(card)
  //       setFirstCard(true)
  //     }
  //     else if (!firstCard) {
  //       setCurrentCardInt(`${parseInt(currentCardInt) + 1}`);
  //       card = overviewCards[parseInt(currentCardInt)]
  //       setCurrentCard(card)
  //     }
  //     else {
  //       setCurrentCardInt('0');
  //       card = overviewCards[0]
  //       setCurrentCard(card)
  //       setFirstCard(false)
  //     }
  //   }, 10000);
  //   return () => clearInterval(interval);
  // }, [overviewCards]);

  return (
    <React.Fragment>
      {overviewCards !== undefined ?
        <Carousel
          animation='slide'
          interval='15000'
          duration='3000'
        >
          {overviewCards?.map((card, i) => {
            return <IncidentItem key={i}>{card}</IncidentItem>;
          })}
        </Carousel> :
        <Timezone />
      }
    </React.Fragment>
  );
};

IncidentOverviewSmall.propTypes = {
  className: PropTypes.string,
};

export default IncidentOverviewSmall;
