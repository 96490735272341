import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box, makeStyles, Card, CardContent, Grid, Typography, CardActions } from "@material-ui/core";
import DashboardServiceCard from "../../../components/Cards/DashboardServiceCard";
import {
  ExpandLess,
  ExpandMore,
  DoneRounded,
  PriorityHighRounded,
  BlockRounded,
} from "@material-ui/icons";
import Hexagon from "../../../components/DashboardItems/Shapes/Hexagon";
import Honeycomb from "../../../components/DashboardItems/ShapeLayouts/Honeycomb";
import ResponsiveHoneycomb from "../../../components/DashboardItems/ShapeLayouts/ResponsiveHoneycomb";
import IncidentOrUptime from "./IncidentOrUptime";
import StandardCard from "../../../components/Cards/StandardCard";

const useStyles = makeStyles((theme) => ({
  root: {},
  tabs: {
    backgroundColor: theme.palette.background.dark,
    // paddingTop: 20,
    // paddingBottom: 20,
    // [theme.breakpoints.down("md")]: {
    //   paddingTop: 60,
    //   paddingBottom: 60,
    // },
  },
  listItem: {
    padding: 20,
    color: theme.palette.text.primary,
  },
  listDiv: {
    marginBottom: 14,
    marginTop: 14,
  },
  listItemText: {
    fontSize: 40,
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
  hexOperational: {
    fill: theme.palette.success.main,
  },
  hexMinor: {
    fill: theme.palette.focus.main,
  },
  hexMajor: {
    fill: theme.palette.warning.main,
  },
  hexCritical: {
    fill: theme.palette.error.main,
  },
  hexFYI: {
    fill: theme.palette.info.main,
  },
  card: {
    // Provide some spacing between cards
    margin: 16,

    // Use flex layout with column direction for components in the card
    // (CardContent and CardActions)
    display: "flex",
    flexDirection: "column",

    // Justify the content so that CardContent will always be at the top of the card,
    // and CardActions will be at the bottom
    justifyContent: "space-between",
  },
  hexAnimation: {
    // animation: `$hexAnimation 2500ms ${theme.transitions.easing.easeInOut}`,
    opacity: 1,
    // animationIterationCount: 'infinite'
  },
  "@keyframes hexAnimation": {
    "0%": {
      opacity: 1,
    },
    "50%": {
      opacity: 0.7
    },
    "100%": {
      opacity: 1,
    }
  }
}));

const Segment = ({ services, className, hexsize, ...rest }) => {
  const classes = useStyles();

  const cardContent = (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ResponsiveHoneycomb
          defaultWidth={10}
          size={hexsize}
          items={services}
          renderItem={(item) => {
            var hexColor = classes.hexOperational
            var hexAnimation = null
            if (item.Incident?.classification == 'Minor') {
              hexColor = classes.hexMinor
              hexAnimation = classes.hexAnimation
            }
            else if (item.Incident?.classification == 'Major') {
              hexColor = classes.hexMajor
              hexAnimation = classes.hexAnimation
            }
            else if (item.Incident?.classification == 'Critical') {
              hexColor = classes.hexCritical
              hexAnimation = classes.hexAnimation
            }
            else if (item.Incident?.classification == 'FYI') {
              hexColor = classes.hexFYI
              hexAnimation = classes.hexAnimation
            }
            return (
              <Hexagon colour={hexColor} animation={hexAnimation}>Hex</Hexagon>
            )
          }}
        />
      </Grid>
    </Grid>
  )

  return (
    <StandardCard
      title='Service Overview'
      cardContent={cardContent}
    />
  );
};

Segment.propTypes = {
  className: PropTypes.string,
};

export default Segment;
