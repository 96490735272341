import React, {
  useRef,
  useState
} from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import {
  Box,
  ButtonBase,
  Divider,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { PersonRounded, LogoutRounded } from '@mui/icons-material';
import useAuth from '../../../hooks/useAuth';
import UserAvatar from '../../../components/Avatars/UserAvatar';
import Fade from '@mui/material/Fade';
import { alpha } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 200,
    backgroundColor: theme.palette.secondary.light,
    borderColor: alpha(theme.palette.primary.light, 0.1),
    borderWidth: 1,
    borderStyle: "solid",
    marginTop: 4,
    zIndex: 100
  },
  icon: {
    color: theme.palette.text.primary
  }
}));

const Account = () => {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const { user, logout } = useAuth();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      history.push('/');
    } catch (err) {
      console.error(err)
    }
  }


  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <UserAvatar />
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
        TransitionComponent={Fade}
        elevation={24}
      >
        <MenuItem component={RouterLink} to="/profile">
          <ListItemIcon className={classes.icon}>
            <PersonRounded fontSize="small" />
          </ListItemIcon>
          <ListItemText>My Profile</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon className={classes.icon}>
            <LogoutRounded fontSize="small" />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}

export default Account;