import React, {
    useState,
    useRef,
    useEffect
} from 'react';
import {
    Badge,
    Box,
    Button,
    IconButton,
    Popover,
    SvgIcon,
    TextField,
    Tooltip,
    Typography,
    makeStyles,
    useTheme
} from '@material-ui/core';
import { FullscreenRounded } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
    badge: {
        height: 10,
        width: 10,
        borderRadius: 5,
        marginTop: 10,
        marginRight: 5
    },
    popover: {
        width: 320,
        padding: theme.spacing(2)
    }
}));

const FullscreenMode = ({
    fullscreen,
    setFullscreen,
}) => {
    const classes = useStyles();

    return (
        <>
            <Tooltip title="Fullscreen Mode">
                <IconButton
                    color="inherit"
                    onClick={() => setFullscreen(!fullscreen)}
                >
                    <SvgIcon fontSize="small">
                        <FullscreenRounded />
                    </SvgIcon>
                </IconButton>
            </Tooltip>
        </>
    );
}

export default FullscreenMode;