export const ADD_ON_ROLES = {
    dashboardViewer: "Dashboard Viewer",
    serviceRegisterEditor: "Service Register Editor",
    serviceRegisterViewer: "Service Register Viewer",
    auditLogsViewer: "Audit Logs Viewer"
};

export const BASE_ROLES = {
    default: "Default",
    restrictedUser: "Restricted User",
    securityUser: "Security User",
    cloudopsUser: "CloudOps User",
    cloudopsManager: "CloudOps Manager",
    admin: "Admin"
};

export const ROLES = {
    ...BASE_ROLES,
    ...ADD_ON_ROLES
};

export const ADD_ON_ROLES_SCOPES = {
    dashboardCanView: "dashboard-can-view",
    serviceRegisterCanView: "serviceregister-can-view",
    serviceRegisterCanEdit: "serviceregister-can-edit",
    serviceRegisterCanDelete: "serviceregister-can-delete",
    serviceRegisterCanCreate: "serviceregister-can-create",
    auditLogsCanView: "auditlogs-can-view"
};

export const BASE_ROLE_SCOPES = {
    restrictedCanView: "restricted-can-view",
    securityCanView: "security-can-view",
    cloudopsCanCreate: "cloudops-can-create",
    cloudopsCanEdit: "cloudops-can-edit",
    cloudopsCanDelete: "cloudops-can-delete",
    cloudopsCanView: "cloudops-can-view",
    adminAll: "admin-all"
};

export const SCOPES = {
    ...BASE_ROLE_SCOPES,
    ...ADD_ON_ROLES_SCOPES
};

export const ADD_ON_ROLE_PERMISSIONS = {
    [ROLES.dashboardViewer]: [SCOPES.dashboardCanView],
    [ROLES.serviceRegisterEditor]: [
        SCOPES.serviceRegisterCanView,
        SCOPES.serviceRegisterCanCreate, 
        SCOPES.serviceRegisterCanDelete, 
        SCOPES.serviceRegisterCanEdit,
    ],
    [ROLES.serviceRegisterViewer]: [SCOPES.serviceRegisterCanView],
};

export const BASE_ROLE_PERMISSIONS = {
    [ROLES.default]: [],
    [ROLES.restrictedUser]: [SCOPES.restrictedCanView],
    [ROLES.securityUser]: [
        SCOPES.restrictedCanView,
        SCOPES.securityCanView,
    ],
    [ROLES.cloudopsUser]: [
        SCOPES.restrictedCanView,
        SCOPES.cloudopsCanView,
    ],
    [ROLES.cloudopsManager]: [
        SCOPES.restrictedCanView,
        SCOPES.cloudopsCanView,
        SCOPES.cloudopsCanEdit,
        SCOPES.cloudopsCanCreate,
        SCOPES.cloudopsCanDelete,
        SCOPES.auditLogsCanView
    ],
    [ROLES.admin]: Object.values(SCOPES)
};

export const PERMISSIONS = {
    ...BASE_ROLE_PERMISSIONS,
    ...ADD_ON_ROLE_PERMISSIONS
};
