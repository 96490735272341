import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Tooltip,
  Toolbar,
  makeStyles,
  SvgIcon,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import {
  MenuRounded,
  IndeterminateCheckBoxRounded,
  AddBoxRounded,
  MenuOpenRounded,
  BugReportRounded
} from '@material-ui/icons'
import { THEMES } from '../../../constants';
import { ReactComponent as DarkLogo } from "../../../media/logos/logo-green.svg";
import { ReactComponent as LightLogo } from "../../../media/logos/logo-black.svg";
import Account from './Account';
import Search from './Search';
import Settings from './Settings';
import DarkMode from './DarkMode';
//import Notifications from './Notifications';
import Notifications from './Notifications2'
import { ViewSidebarRounded } from '@mui/icons-material'

const TopBar = ({
  className,
  onMobileNavOpen,
  onNavOpen,
  openNav,
  ...rest
}) => {
  const barWidth = useMediaQuery('(min-width:1280px)') ? (openNav ? 'calc(100% - 260px)' : '100%') : '100%';

  const useStyles = makeStyles((theme) => ({
    root: {
      zIndex: theme.zIndex.drawer + 100,
      width: barWidth,
      ...theme.name === THEMES.LIGHT ? {
        //boxShadow: 'none',
        backgroundColor: theme.palette.background.defaultTransparent,
        color: theme.palette.text.secondary
      } : {},
      ...theme.name === THEMES.DARK ? {
        backgroundColor: theme.palette.background.defaultTransparent,
        color: theme.palette.text.secondary
      } : {}
    },
    toolbar: {
      minHeight: 64,
      paddingLeft: '0.5%'
    }
  }
  ));

  const classes = useStyles();

  const sageLogo = (
    < RouterLink to="/home" >
      <Box p={2} paddingBottom={0} paddingTop={0} display="flex" justifyContent="center" fontSize="3rem">
        <SvgIcon height="100%" width="100%" viewBox="200 -150 100 550" fontSize="inherit">
          {useTheme().name === THEMES.LIGHT ? <LightLogo /> : <DarkLogo />}
        </SvgIcon>
      </Box>
    </RouterLink >
  )

  return (
    <AppBar
      elevation={0}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar
        className={classes.toolbar}
      >
        {!openNav ?
          <React.Fragment>
            {sageLogo}
          </React.Fragment>
          :
          <React.Fragment />
        }
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuRounded />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <Tooltip title={openNav ? "Close Sidebar" : "Open Sidebar"}>
            <IconButton
              color="inherit"
              onClick={onNavOpen}
            >
              <SvgIcon fontSize="small">
                {openNav ? <MenuOpenRounded /> : <MenuRounded />}
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </Hidden>
        {/* <Search /> */}
        <Box
          ml={2}
          flexGrow={1}
        />
        {/* <Notifications /> */}
        <DarkMode />
        {/* <Settings /> */}
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar >
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
  onNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => { },
  onNavOpen: () => { }
};

export default TopBar;