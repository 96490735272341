import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Route } from "react-router-dom";
import SidebarLayout from "./layouts/SidebarLayout";
import FullscreenLayout from "./layouts/FullscreenLayout";
import LoadingScreen from "./components/LoadingScreen";
import AuthGuard from "./components/Guards/AuthGuard";
import GuestGuard from "./components/Guards/GuestGuard";
import ServiceHealthDashboard from "./views/execDashboards/ServiceHealthDashboard";
import IncidentOverviewDashboard from "./views/execDashboards/IncidentOverviewDashboard";
import {
  PostAddRounded,
  PeopleRounded,
  ErrorRounded,
  DynamicFeedRounded,
  DashboardRounded,
  CalculateRounded,
  AssignmentRounded,
  DescriptionRounded,
  BusinessRounded,
  ArticleRounded,
  FindInPageRounded
} from "@mui/icons-material";
import { SCOPES } from "./permissionMaps";
import PermissionsGateContext from "./contexts/PermissionsGateContext";
import NotFoundPage from './components/Pages/NotFoundPage';
import { AnimatePresence } from 'framer-motion/dist/framer-motion';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Route
      render={({ location }) => (
        <AnimatePresence exitBeforeEnter initial={false}>
          <Switch location={location} key={location.pathname}>
            {routes.map((route, i) => {
              if (route.path) {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;
                const Scopes = route.scopes || [];

                return (

                  <Route
                    key={i}
                    path={route.path}
                    exact={route.exact}
                    render={(props) => (
                      <Guard>
                        <Layout>
                          {route.routes ? (
                            <PermissionsGateContext
                              scopes={Scopes}>
                              {renderRoutes(route.routes)}
                            </PermissionsGateContext>
                          ) : (
                            <PermissionsGateContext
                              scopes={Scopes}>
                              <Component {...props} />
                            </PermissionsGateContext>
                          )}
                        </Layout>
                      </Guard>
                    )}
                  />
                );
              }
            })}
            <Route
              path="*"
              key={-1}
              render={(props) => (
                <AuthGuard>
                  {<NotFoundPage />}
                </AuthGuard>
              )}
            />
          </Switch>
        </AnimatePresence>
      )}
    />
  </Suspense >
);

const routes = [
  {
    exact: true,
    guard: GuestGuard,
    path: "/",
    component: lazy(() => import("../src/views/LoginView")),
  },
  {
    exact: true,
    layout: SidebarLayout,
    guard: AuthGuard,
    path: "/home",
    component: lazy(() =>
      import("../src/views/UserDashboard")
    ),
  },
  {
    exact: true,
    layout: SidebarLayout,
    guard: AuthGuard,
    path: "/profile",
    component: lazy(() =>
      import("./views/ProfilePage")
    ),
  },
  {
    exact: true,
    layout: SidebarLayout,
    guard: AuthGuard,
    path: "/apps",
    component: lazy(() => import("./views/apps")),
  },
  {
    exact: true,
    layout: SidebarLayout,
    guard: AuthGuard,
    path: "/release-notes",
    component: lazy(() => import("./views/releaseNotes")),
  },
  // {
  //   path: "/general",
  //   section: "General",
  //   guard: AuthGuard,
  //   layout: SidebarLayout,
  //   routes: [
  //     // {
  //     //   exact: true,
  //     //   path: "/reports/dashboard",
  //     //   component: lazy(() =>
  //     //     import("../src/views/LoginView/reports/DashboardView")
  //     //   ),
  //     // },
  //     // {
  //     //   exact: true,
  //     //   path: "/reports/metrics",
  //     //   appName: "Monthly Metrics",
  //     //   component: lazy(() =>
  //     //     import("./views/LoginView/reports/MonthlyMetrics")
  //     //   ),
  //     // },
  //     // {
  //     //   exact: true,
  //     //   path: "/reports/metrics/:serviceId",
  //     //   component: lazy(() =>
  //     //     import("./views/LoginView/reports/MonthlyMetrics/MetricsDashboard")
  //     //   ),
  //     // },
  //     // {
  //     //   exact: true,
  //     //   path: "/reports/metrics/:serviceId/add",
  //     //   component: lazy(() =>
  //     //     import("./views/LoginView/reports/MonthlyMetrics/AddMonthlyMetrics")
  //     //   ),
  //     // },
  //     // {
  //     //   exact: true,
  //     //   path: "/reports/managersDashboard",
  //     //   component: lazy(() =>
  //     //     import("../src/views/LoginView/reports/ManagersView")
  //     //   ),
  //     // },
  //   ],
  // },
  // {
  //   path: "/reports",
  //   section: "Reports",
  //   guard: AuthGuard,
  //   layout: SidebarLayout,
  //   routes: [
  //     {
  //       exact: true,
  //       path: "/reports/weeklyReport",
  //       appName: "Weekly Report",
  //       logo: DescriptionRounded,
  //       component: lazy(() =>
  //         import("./views/LoginView/reports/WeeklyReport")
  //       ),
  //     },
  //     {
  //       exact: true,
  //       path: "/reports/weeklyReport/listReports",
  //       component: lazy(() =>
  //         import("./views/LoginView/reports/WeeklyReport/ListReports")
  //       ),
  //     },
  //     {
  //       exact: true,
  //       path: "/reports/weeklyReport/updateForm",
  //       component: lazy(() =>
  //         import("./views/LoginView/reports/WeeklyReport/UpdateForm")
  //       ),
  //     },
  //     {
  //       exact: true,
  //       path: "/reports/weeklyReport/viewUpdates",
  //       component: lazy(() =>
  //         import("./views/LoginView/reports/WeeklyReport/ViewUpdates")
  //       ),
  //     },
  //     {
  //       exact: true,
  //       path: "/reports/weeklyReport/generateReport",
  //       component: lazy(() =>
  //         import("./views/LoginView/reports/WeeklyReport/GenerateReport")
  //       ),
  //     },
  //   ]
  // },
  {
    path: "/governance",
    section: "Governance",
    scopes: [SCOPES.restrictedCanView],
    guard: AuthGuard,
    layout: SidebarLayout,
    routes: [
      {
        exact: true,
        path: "/governance/service-register",
        scopes: [SCOPES.cloudopsCanView, SCOPES.securityCanView, SCOPES.serviceRegisterCanView],
        component: lazy(() =>
          import("./views/governance/ServiceRegister")
        ),
      },
      {
        exact: true,
        scopes: [SCOPES.cloudopsCanView, SCOPES.securityCanView, SCOPES.serviceRegisterCanView],
        path: "/governance/service-register/view",
        appName: "Service Register       (Confidential assets)",
        logo: AssignmentRounded,
        component: lazy(() =>
          import("./views/governance/ServiceRegister/ViewCondensedServiceRegister")
        ),
      },
      {
        exact: true,
        scopes: [SCOPES.cloudopsCanView, SCOPES.securityCanView, SCOPES.serviceRegisterCanView],
        path: "/governance/service-register/viewAll",
        component: lazy(() =>
          import("./views/governance/ServiceRegister/ViewAllServiceRegister")
        ),
      },
      {
        exact: true,
        path: "/governance/service-register/add",
        component: lazy(() =>
          import("./views/governance/ServiceRegister/AddService")
        ),
        scopes: [SCOPES.cloudopsCanCreate, SCOPES.serviceRegisterCanCreate],
      },
      {
        exact: true,
        path: "/governance/service-register/edit",
        component: lazy(() =>
          import("./views/governance/ServiceRegister/EditServiceRegister")
        ),
        scopes: [SCOPES.cloudopsCanEdit, SCOPES.serviceRegisterCanEdit],
      },
      {
        exact: true,
        path: "/governance/service-register/edit/:serviceId",
        component: lazy(() =>
          import("./views/governance/ServiceRegister/EditServiceRegister/EditService")
        ),
        scopes: [SCOPES.cloudopsCanEdit, SCOPES.serviceRegisterCanEdit],
      },
      {
        exact: true,
        path: "/governance/service-register/edit/:serviceId/:section",
        component: lazy(() =>
          import("./views/governance/ServiceRegister/EditServiceRegister/EditService/EditServiceView")
        ),
        scopes: [SCOPES.cloudopsCanEdit, SCOPES.serviceRegisterCanEdit],
      },
      {
        logo: DashboardRounded,
        scopes: [SCOPES.restrictedCanView],
        appName: "Availability",
        href: 'https://app.powerbi.com/Redirect?action=OpenApp&appId=f58364c4-34ae-4b5f-9717-c3f321a2803e&ctid=3e32dd7c-41f6-492d-a1a3-c58eb02cf4f8',
      },
      {
        logo: DashboardRounded,
        scopes: [SCOPES.restrictedCanView],
        appName: "Azure Usage",
        href: 'https://app.powerbi.com/links/tIi6gZ2X3k?ctid=3e32dd7c-41f6-492d-a1a3-c58eb02cf4f8&pbi_source=linkShare',
      },
      {
        logo: DashboardRounded,
        scopes: [SCOPES.restrictedCanView],
        appName: "Incidents",
        href: 'https://sage.service-now.com/nav_to.do?uri=%2F$pa_dashboard.do%3Fsysparm_dashboard%3D6cff4fc61b0d30143b7921f0b24bcbe1',
      },
      {
        logo: DashboardRounded,
        scopes: [SCOPES.restrictedCanView],
        appName: "People",
        href: 'https://app.powerbi.com/links/dBi-oVLoWO?ctid=3e32dd7c-41f6-492d-a1a3-c58eb02cf4f8&pbi_source=linkShare',
      },
      {
        logo: DashboardRounded,
        scopes: [SCOPES.restrictedCanView],
        appName: "Risk",
        href: 'https://app.powerbi.com/links/RhbHhR6ev8?ctid=3e32dd7c-41f6-492d-a1a3-c58eb02cf4f8&pbi_source=linkShare',
      },
    ],
  },
  // {
  //   path: "/utilities",
  //   section: "Utilities",
  //   guard: AuthGuard,
  //   layout: SidebarLayout,
  //   routes: [
  //     {
  //       exact: true,
  //       path: "/utilities/teamCalendars",
  //       component: lazy(() =>
  //         import("../src/views/LoginView/reports/Utilities/TeamCalendars")
  //       ),
  //     },
  //   ],
  // },
  {
    path: '/service-centre',
    section: "Service Centre",
    scopes: [SCOPES.cloudopsCanView],
    guard: AuthGuard,
    layout: SidebarLayout,
    routes: [
      {
        exact: true,
        path: "/service-centre/incidents-overview",
        appName: "Incidents Overview",
        scopes: [SCOPES.cloudopsCanView],
        logo: ErrorRounded,
        component: lazy(() =>
          import("./views/serviceCentre/Incidents")
        ),
      },
      // {
      //   exact: true,
      //   path: "/governance/changeManagementOverview",
      //   appName: "Change Management Overview",
      //   component: lazy(() =>
      //     import("../src/views/LoginView/reports/ChangeManagement/Overview")
      //   ),
      // },
      // {
      //   exact: true,
      //   path: "/governance/rfcApproval",
      //   appName: "RFC Approval",
      //   logo: "/assets/images/sq-12.jpg",
      //   component: lazy(() =>
      //     import("../src/views/LoginView/reports/ChangeManagement/rfcApproval")
      //   ),
      // },
      {
        exact: true,
        path: "/service-centre/risk-impact-calculator",
        scopes: [SCOPES.cloudopsCanView],
        appName: "Risk/Impact Calculator",
        logo: CalculateRounded,
        component: lazy(() =>
          import("./views/serviceCentre/ChangeManagement/RiskCalculator")
        ),
      },
      {
        exact: true,
        path: "/service-centre/risk-impact-calculator-audit-log",
        scopes: [SCOPES.auditLogsCanView],
        appName: "Risk/Impact Calculator Audit Log",
        logo: FindInPageRounded,
        component: lazy(() =>
          import("./views/serviceCentre/AuditLogs/ViewRiskImpactCalcAuditLog")
        ),
      },
    ],
  },
  {
    path: '/executive-dashboards',
    section: "Executive Dashboards",
    scopes: [SCOPES.cloudopsCanView, SCOPES.dashboardCanView],
    guard: AuthGuard,
    layout: FullscreenLayout,
    routes: [
      {
        exact: true,
        path: "/executive-dashboards/service-health",
        appName: "Service Health",
        logo: DashboardRounded,
        component: ServiceHealthDashboard
      },
      {
        exact: true,
        path: "/executive-dashboards/current-incidents",
        appName: "Current Incidents",
        logo: DashboardRounded,
        component: IncidentOverviewDashboard
      },
    ],
  },
  {
    path: '/admin',
    section: "Admin",
    scopes: [SCOPES.adminAll],
    guard: AuthGuard,
    layout: SidebarLayout,
    routes: [
      {
        exact: true,
        path: "/admin/users-and-roles",
        logo: PeopleRounded,
        component: lazy(() =>
          import("./views/admin/UsersAndRoles/index")
        ),
      },
      {
        exact: true,
        path: "/admin/users-and-roles/add",
        logo: PeopleRounded,
        component: lazy(() =>
          import("./views/admin/UsersAndRoles/AddUsers/index")
        ),
      },
      {
        exact: true,
        path: "/admin/users-and-roles/view",
        appName: "Users and Roles",
        logo: PeopleRounded,
        component: lazy(() =>
          import("./views/admin/UsersAndRoles/ViewUsers/index")
        ),
      },
      {
        exact: true,
        path: "/admin/users-and-roles/edit/:userId",
        component: lazy(() =>
          import("./views/admin/UsersAndRoles/EditUserRoles/index")
        ),
      },
    ],
  },
  {
    section: "Other",
    guard: AuthGuard,
    routes: [
      {
        logo: BusinessRounded,
        appName: "Your Sage",
        href: 'https://sage.unily.com/sites/cloud-operations/SitePageModern/156061/home',
      },
      {
        logo: ArticleRounded,
        appName: "Confluence",
        href: 'https://confluence.sage.com/pages/viewpage.action?spaceKey=CLOUDOPS&title=Cloud+Operations',
      },
    ],
  },
];

export default routes;
