import React from 'react';
import {
    Avatar,
    makeStyles
} from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    avatar: {
        height: 32,
        width: 32
    }
}));

const UserAvatar = ({ className }) => {
    const classes = useStyles();
    const { user } = useAuth();

    return (
        <Avatar
            alt={(user === null ? "User" : user.name)}
            className={clsx(classes.avatar, className)}
            src={(user === null ? null : user.photo)}
        />
    )
}

export default UserAvatar;