import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Card, CardContent, Grid, makeStyles, Typography } from "@material-ui/core";
import useIsMountedRef from "../../../../hooks/useIsMountedRef";
import "../../../../App.css";
import Clock from "../../../../components/DashboardItems/Clock";
import StandardCard from "../../../../components/Cards/StandardCard";

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    textAlign: "center",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(3, 2),
    "&:not(:last-of-type)": {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
}));

const Timezone = ({ className, ...rest }) => {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();

  const cardContent = (
    <Grid container spacing={1} justify="center">
      <Grid item lg={4} md={6} xs={6}>
        <Clock title="London" datediff={0} />
      </Grid>
      <Grid item lg={4} md={6} xs={6}>
        <Clock title="Toronto" datediff={-5} />
      </Grid>
      <Grid item lg={4} md={6} xs={6}>
        <Clock title="Johannesburg" datediff={+1} />
      </Grid>
      <Grid item lg={4} md={6} xs={6}>
        <Clock title="Paris" datediff={+1} />
      </Grid>
      <Grid item lg={4} md={6} xs={6}>
        <Clock title="California" datediff={-7} />
      </Grid>
      <Grid item lg={4} md={6} xs={6}>
        <Clock title="Singapore" datediff={+7} />
      </Grid>
    </Grid>
  )

  return (
    <StandardCard
      cardContent={cardContent} />
  );
};

Timezone.propTypes = {
  className: PropTypes.string,
};

export default Timezone;
