import _ from 'lodash';
import {
  createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core';
import { THEMES } from '../constants';
import { softShadows, strongShadows } from './shadows';
import typography from './typography';

import SageUIRegularWoff2 from '../media/fonts/Sage_UI-Regular.woff2';
import SageUIMediumWoff2 from '../media/fonts/Sage_UI-Medium.woff2';
import SageUIBoldWoff2 from '../media/fonts/Sage_UI-Bold.woff2';
import SageUIRegularWoff from '../media/fonts/Sage_UI-Regular.woff';
import SageUIMediumWoff from '../media/fonts/Sage_UI-Medium.woff';
import SageUIBoldWoff from '../media/fonts/Sage_UI-Bold.woff';

const sageURegularWoff = {
  fontFamily: 'SageUI',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: '400',
  src: `
   local('SageUI'),
   local('Sage_UI-Regular'),
   url(${SageUIRegularWoff}) format('woff')
 `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const sageUiMediumWoff = {
  fontFamily: 'SageUI',
  fontStyle: 'semi-bold',
  fontDisplay: 'swap',
  fontWeight: '600',
  src: `
    local('SageUI'),
    local('Sage_UI-Medium'),
    url(${SageUIMediumWoff}) format('woff')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const sageUiBoldWoff = {
  fontFamily: 'SageUI',
  fontStyle: 'bold',
  fontDisplay: 'swap',
  fontWeight: '700',
  src: `
    local('SageUI'),
    local('Sage_UI-Bold'),
    url(${SageUIBoldWoff}) format('woff')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const sageURegularWoff2 = {
  fontFamily: 'SageUI',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: '400',
  src: `
   local('SageUI'),
   local('Sage_UI-Regular'),
   url(${SageUIRegularWoff2}) format('woff2')
 `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const sageUiMediumWoff2 = {
  fontFamily: 'SageUI',
  fontStyle: 'semi-bold',
  fontDisplay: 'swap',
  fontWeight: '600',
  src: `
    local('SageUI'),
    local('Sage_UI-Medium'),
    url(${SageUIMediumWoff2}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const sageUiBoldWoff2 = {
  fontFamily: 'SageUI',
  fontStyle: 'bold',
  fontDisplay: 'swap',
  fontWeight: '700',
  src: `
    local('SageUI'),
    local('Sage_UI-Bold'),
    url(${SageUIBoldWoff2}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const baseOptions = {
  direction: 'ltr',
  typography: {
    fontFamily: ['"Open Sans"', 'SageUI', 'Roboto'].join(','),
    h1: {
      fontWeight: 700,
      fontSize: 35,
      letterSpacing: '-0.24px',
    },
    h2: {
      fontWeight: 700,
      fontSize: 29,
      letterSpacing: '-0.24px'
    },
    h3: {
      fontWeight: 600,
      fontSize: 24,
      letterSpacing: '-0.06px',
    },
    h4: {
      fontWeight: 600,
      fontSize: 20,
      letterSpacing: '-0.06px'
    },
    h5: {
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: '-0.05px'
    },
    h6: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: '-0.05px'
    },
    overline: {
      fontWeight: 500
    },
    bold: {
      fontWeight: 700
    }
  },
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 24,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    },
    MuiCard: {
      root: {
        borderRadius: 24,
        overflow: 'hidden',
        padding: 16,
      }
    },
    MuiButton: {
      root: {
        borderRadius: 24,
        boxShadow: 'none',
        overflow: 'hidden',
        fontWeight: '600',
        textTransform: 'none',
        '&:hover': { // changes colors for hover state
          backgroundColor: '#E2E2E2',
          color: '#000000',
          transition: "all 0.2s linear",
          boxShadow: 'none',
        },

      },
      containedSecondary: {
        boxShadow: 'none',
        '&:hover': { // changes colors for hover state
          backgroundColor: '#E2E2E2',
          boxShadow: 'none',
          color: '#000000',
          transition: "all 0.2s linear",
        },
      },
      containedPrimary: {
        boxShadow: 'none',
        '&:hover': { // changes colors for hover state
          backgroundColor: '#E2E2E2',
          boxShadow: 'none',
          color: '#000000',
          transition: "all 0.2s linear",
        },
      },
    },
    MuiSnackbar: {
      root: {
        borderRadius: 24,
        overflow: 'hidden',
      }
    },
    MuiPaper: {
      root: {
      },
      rounded: {
        borderRadius: 24,
        overflow: 'hidden',
      }
    },
    MuiMenu: {
      root: {
        borderRadius: 24,
        overflow: 'hidden',
      }
    },
    MuiIconButton: {
      root: {
        color: 'primary',
      }
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [sageURegularWoff2, sageUiMediumWoff2, sageUiBoldWoff2, sageURegularWoff, sageUiMediumWoff, sageUiBoldWoff],
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: 'primary',
        '&$checked': {
          color: 'primary',
        },
      },
    },
    MUIDataTableResize: {
      resizer: {
        height: '3%',
        top: '2.5%'
      }
    },
    MuiInputLabel: {
      formControl: {
        transform: 'translate(14px, 20px) scale(1)'
      },
      shrink: {
        transform: 'translate(14px, -6px) scale(0.75)',
        padding: '0px 10px',
        zIndex: 1
      }
    }
  }
};

const themesOptions = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputLabel: {
        shrink: {
          backgroundColor: '#FFFFFF',
        }
      },
      MuiCssBaseline: {
        '@global': {
          'a': {
            'color': '#008146',
            // '&:hover': {
            //   'color': '#006738'
            // },
            // '&:visited': {
            //   'color': '#004D2A'
            // }
          }
        },
      },
    },
    palette: {
      type: 'light',
      common: {
        black: '#000000',
        white: '#FFFFFF',
        brilliantGreen: '#00D639',
        jade: '#008C4E',
        jadeAdjusted: '#008146',
        jadeT6: '#A4FBC2',
        jadeT7: '#D1FDE0',
        grayS4: '#1B1B1B',
        grayS3: '#303030',
        grayS2: '#474747',
        grayS1: '#5E5E5E',
        grayT1: '#777777',
        grayT2: '#919191',
        grayT3: '#ABABAB',
        grayT4: '#C6C6C6',
        grayT5: '#E2E2E2',
        grayT6: '#F0F0F0',
      },
      primary: {
        main: '#000000',
        contrastText: '#FFFFFF'
      },
      secondary: {
        main: '#FFFFFF', // Color of buttons and the text contained in them
        contrastText: '#000000'
      },
      error: {
        main: '#CD384B',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#EF6700',
        contrastText: '#000000'
      },
      info: {
        main: '#0060A7',
        contrastText: '#FFFFFF'
      },
      success: {
        main: '#008A21',
        contrastText: '#FFFFFF'
      },
      focus: {
        main: '#FFB500',
        contrastText: '#000000'
      },
      text: {
        primary: '#000000',
        secondary: '#000000',
        contrastPrimary: '#FFFFFF',
        contrastSecondary: '#adb0b5'
      },
      background: {
        default: '#F2F5F6',
        paper: '#ffffff', // This sets NavBar color and also components in dashboard
        defaultTransparent: 'rgba(242, 245, 246, 0.98)',
        white: '#FFFFFF',
        black: '#000000',
        grayS4: 'rgba(27, 27, 27, 1)',
        black95: 'rgba(0, 0, 0, 0.95)',
        black04: 'rgba(0, 0, 0, 0.04)'
      }
    },
    shadows: softShadows,
    transitions: {
      easing: {
        // This is the most common easing curve.
        easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
        // Objects enter the screen at full velocity from off-screen and
        // slowly decelerate to a resting point.
        easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
        // Objects leave the screen at full velocity. They do not decelerate when off-screen.
        easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
        // The sharp curve is used by objects that may return to the screen at any time.
        sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
      },
      duration: {
        shortest: 150,
        shorter: 200,
        short: 250,
        // most basic recommended timing
        standard: 300,
        // this is to be used in complex animations
        complex: 375,
        // recommended when something is entering screen
        enteringScreen: 225,
        // recommended when something is leaving screen
        leavingScreen: 195,
      },
    }
  },
  {
    name: THEMES.DARK,
    overrides: {
      MuiButton: {
        root: {
          '&:hover': { // changes colors for hover state
            backgroundColor: 'rgba(226, 226, 226, 0.4)',
            color: '#FFFFFF',
          },
        },
        containedSecondary: {
          '&:hover': { // changes colors for hover state
            backgroundColor: 'rgba(226, 226, 226, 0.4)',
            color: '#FFFFFF',
          },
        },
        containedPrimary: {
          '&:hover': { // changes colors for hover state
            backgroundColor: 'rgba(226, 226, 226, 0.4)',
            color: '#FFFFFF',
          },
        },
      },
      MuiInputLabel: {
        shrink: {
          backgroundColor: '#1B1B1B',
        }
      },
      MuiCssBaseline: {
        '@global': {
          'a': {
            'color': '#00D639',
            // '&:hover': {
            //   'color': '#00D639'
            // },
            // '&:visited': {
            //   'color': '#66E688'
            // }
          }
        },
      },
    },
    palette: {
      type: 'dark',
      common: {
        black: '#000000',
        white: '#FFFFFF',
        brilliantGreen: '#00D639',
        jade: '#008C4E',
        jadeAdjusted: '#008146',
        jadeT6: '#A4FBC2',
        jadeT7: '#D1FDE0',
        grayS4: '#1B1B1B',
        grayS3: '#303030',
        grayS2: '#474747',
        grayS1: '#5E5E5E',
        grayT1: '#777777',
        grayT2: '#919191',
        grayT3: '#ABABAB',
        grayT4: '#C6C6C6',
        grayT5: '#E2E2E2',
        grayT6: '#F0F0F0',
      },
      primary: {
        main: '#FFFFFF',
        contrastText: '#000000'
      },
      secondary: {
        main: '#000000',
        contrastText: '#FFFFFF'
      },
      error: {
        main: '#CD384B',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#EF6700',
        contrastText: '#000000'
      },
      info: {
        main: '#0060A7',
        contrastText: '#FFFFFF'
      },
      success: {
        main: '#008A21',
        contrastText: '#FFFFFF'
      },
      focus: {
        main: '#FFB500',
        contrastText: '#000000'
      },
      // action: {
      //   active: 'rgba(255, 255, 255, 0.54)',
      //   hover: 'rgba(255, 255, 255, 0.04)',
      //   selected: 'rgba(255, 255, 255, 0.08)',
      //   disabled: 'rgba(255, 255, 255, 0.26)',
      //   disabledBackground: 'rgba(255, 255, 255, 0.12)',
      //   focus: 'rgba(255, 255, 255, 0.12)'
      // },
      text: {
        primary: '#FFFFFF',
        secondary: '#FFFFFF',
        contrastPrimary: '#000000',
        contrastSecondary: '#64676b'
      },
      background: {
        default: '#000000F2',
        paper: '#1B1B1B',
        secondaryCard: '#3a3b3c',
        defaultTransparent: '#000000F2',
        white: '#FFFFFF',
        black: '#000000',
        grayS4: 'rgba(27, 27, 27, 1)',
        black95: 'rgba(0, 0, 0, 0.95)',
        black04: 'rgba(0, 0, 0, 0.04)'
      },
    },
    shadows: softShadows
  },
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge(
      {},
      baseOptions,
      themeOptions,
      { direction: config.direction }
    )
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
