import * as React from "react";
import clsx from "clsx";
import { HoneycombContext } from "../helpers";
import {
  makeStyles,
} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  animatedItem: {
    animation: `$myEffect 2000ms ${theme.transitions.easing.easeInOut}`,
    opacity: 1,
    animationIterationCount: 'infinite'
  },
  "@keyframes myEffect": {
    "0%": {
      opacity: 1,
    },
    "50%": {
      opacity: 0.7
    },
    "100%": {
      opacity: 1,
    }
  }
}
)
)

const Hexagon = ({
  children,
  colour,
  animation,
  className,
  style = {},
}) => {
  const { gap } = React.useContext(HoneycombContext);
  const classes = useStyles();
  return (
    <div
      className={className}
      style={{
        ...style,
        position: "absolute",
        top: gap / 2,
        left: gap / 2,
        right: gap / 2,
        bottom: gap / 2,
        pointerEvents: "auto",
      }}
    >
      <svg width='100%' height='100%' viewBox="0 0 100 100" preserveAspectRatio="none">
        <polygon points="50 0, 100 25, 100 75, 50 100, 0 75, 0 25" className={clsx(animation, colour)} />
      </svg>
    </div>
  );
};

export default Hexagon;