/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const initUser = /* GraphQL */ `
  query InitUser(
    $email: String
    $sub: String
    $userId: String
    $userPoolId: String
    $m2mUser: Boolean
  ) {
    initUser(
      email: $email
      sub: $sub
      userId: $userId
      userPoolId: $userPoolId
      m2mUser: $m2mUser
    )
  }
`;
export const addUserCognitoGroups = /* GraphQL */ `
  query AddUserCognitoGroups(
    $userRoles: String
    $userPoolId: String
    $email: String
    $m2mUser: Boolean
  ) {
    addUserCognitoGroups(
      userRoles: $userRoles
      userPoolId: $userPoolId
      email: $email
      m2mUser: $m2mUser
    )
  }
`;
export const updateUserCognitoGroups = /* GraphQL */ `
  query UpdateUserCognitoGroups(
    $userRoles: String
    $userPoolId: String
    $email: String
    $m2mUser: Boolean
  ) {
    updateUserCognitoGroups(
      userRoles: $userRoles
      userPoolId: $userPoolId
      email: $email
      m2mUser: $m2mUser
    )
  }
`;
export const removeUserCognitoGroups = /* GraphQL */ `
  query RemoveUserCognitoGroups(
    $userRoles: String
    $userPoolId: String
    $email: String
    $m2mUser: Boolean
  ) {
    removeUserCognitoGroups(
      userRoles: $userRoles
      userPoolId: $userPoolId
      email: $email
      m2mUser: $m2mUser
    )
  }
`;
export const serviceRegisterEdit = /* GraphQL */ `
  query ServiceRegisterEdit($service: String, $token: String) {
    serviceRegisterEdit(service: $service, token: $token)
  }
`;
export const serviceRegisterAdd = /* GraphQL */ `
  query ServiceRegisterAdd($service: String, $token: String) {
    serviceRegisterAdd(service: $service, token: $token)
  }
`;
export const serviceRegisterDelete = /* GraphQL */ `
  query ServiceRegisterDelete($service: String, $token: String) {
    serviceRegisterDelete(service: $service, token: $token)
  }
`;
export const generateWeeklyReport = /* GraphQL */ `
  query GenerateWeeklyReport($updates: String) {
    generateWeeklyReport(updates: $updates)
  }
`;
export const incidentPostRiskImpact = /* GraphQL */ `
  query IncidentPostRiskImpact($values: String, $token: String) {
    incidentPostRiskImpact(values: $values, token: $token)
  }
`;
export const getAllIncidents = /* GraphQL */ `
  query GetAllIncidents {
    getAllIncidents {
      sys_id
      number
      opened_at
      description
      short_description
      status
      createdBy
    }
  }
`;
export const incidentTrafficLight = /* GraphQL */ `
  query IncidentTrafficLight {
    incidentTrafficLight {
      sys_id
      number
      opened_at
      description
      short_description
      status
      createdBy
    }
  }
`;
export const realTimeIncidents = /* GraphQL */ `
  query RealTimeIncidents {
    realTimeIncidents {
      id
      serviceRegisterId
      classification
      updatedAt
      createdAt
      rootCause
      customerImpact
      monitoringResolvedComms
      serviceStatus
      regions
      updateStatus
      emailUpdates
    }
  }
`;
export const serviceNowAssignmentGroupsGet = /* GraphQL */ `
  query ServiceNowAssignmentGroupsGet($token: String) {
    serviceNowAssignmentGroupsGet(token: $token)
  }
`;
export const serviceNowManagersGet = /* GraphQL */ `
  query ServiceNowManagersGet($token: String) {
    serviceNowManagersGet(token: $token)
  }
`;
export const serviceRegisterGet = /* GraphQL */ `
  query ServiceRegisterGet {
    serviceRegisterGet
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoId
      email
      name
      displayName
      firstName
      surname
      jobTitle
      officeLocation
      roles
      photo
      createdAt
      updatedAt
      lastSignIn
      lastOnline
      online
      m2mUser
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      nextToken
    }
  }
`;
export const getRiskImpactCalculator = /* GraphQL */ `
  query GetRiskImpactCalculator($id: ID!) {
    getRiskImpactCalculator(id: $id) {
      id
      createdAt
      updatedAt
      impactNumberValue
      riskNumberValue
      riskLevel
      impactLevel
      approvalLevel
      changeNumber
      riskQuestions
      impactQuestions
      user {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      owner
      userEmail
      submitted
    }
  }
`;
export const listRiskImpactCalculators = /* GraphQL */ `
  query ListRiskImpactCalculators(
    $filter: ModelRiskImpactCalculatorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRiskImpactCalculators(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        impactNumberValue
        riskNumberValue
        riskLevel
        impactLevel
        approvalLevel
        changeNumber
        riskQuestions
        impactQuestions
        user {
          id
          cognitoId
          email
          name
          displayName
          firstName
          surname
          jobTitle
          officeLocation
          roles
          photo
          createdAt
          updatedAt
          lastSignIn
          lastOnline
          online
          m2mUser
        }
        owner
        userEmail
        submitted
      }
      nextToken
    }
  }
`;
export const getWeeklyReport = /* GraphQL */ `
  query GetWeeklyReport($id: ID!) {
    getWeeklyReport(id: $id) {
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listWeeklyReports = /* GraphQL */ `
  query ListWeeklyReports(
    $filter: ModelWeeklyReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWeeklyReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getUpdate = /* GraphQL */ `
  query GetUpdate($id: ID!) {
    getUpdate(id: $id) {
      user {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      id
      region
      service
      wwwContent
      wdgwContent
      pfnwContent
      segment
      wwwIsHighlight
      createdAt
      owner
      updatedAt
    }
  }
`;
export const listUpdates = /* GraphQL */ `
  query ListUpdates(
    $filter: ModelUpdateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUpdates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        user {
          id
          cognitoId
          email
          name
          displayName
          firstName
          surname
          jobTitle
          officeLocation
          roles
          photo
          createdAt
          updatedAt
          lastSignIn
          lastOnline
          online
          m2mUser
        }
        id
        region
        service
        wwwContent
        wdgwContent
        pfnwContent
        segment
        wwwIsHighlight
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWeeklyReportUpdate = /* GraphQL */ `
  query GetWeeklyReportUpdate($id: ID!) {
    getWeeklyReportUpdate(id: $id) {
      user {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      id
      region
      service
      content
      highlight
      updateType
      segment
      reportArea
      subSegment
      approved
      createdAt
      owner
      updatedAt
    }
  }
`;
export const listWeeklyReportUpdates = /* GraphQL */ `
  query ListWeeklyReportUpdates(
    $filter: ModelWeeklyReportUpdateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWeeklyReportUpdates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        user {
          id
          cognitoId
          email
          name
          displayName
          firstName
          surname
          jobTitle
          officeLocation
          roles
          photo
          createdAt
          updatedAt
          lastSignIn
          lastOnline
          online
          m2mUser
        }
        id
        region
        service
        content
        highlight
        updateType
        segment
        reportArea
        subSegment
        approved
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHighlight = /* GraphQL */ `
  query GetHighlight($id: ID!) {
    getHighlight(id: $id) {
      user {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      id
      segment
      createdAt
      updatedAt
      content
      service
      serviceId
      owner
    }
  }
`;
export const listHighlights = /* GraphQL */ `
  query ListHighlights(
    $filter: ModelHighlightFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHighlights(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        user {
          id
          cognitoId
          email
          name
          displayName
          firstName
          surname
          jobTitle
          officeLocation
          roles
          photo
          createdAt
          updatedAt
          lastSignIn
          lastOnline
          online
          m2mUser
        }
        id
        segment
        createdAt
        updatedAt
        content
        service
        serviceId
        owner
      }
      nextToken
    }
  }
`;
export const getUserByEmail = /* GraphQL */ `
  query GetUserByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoId
        email
        name
        displayName
        firstName
        surname
        jobTitle
        officeLocation
        roles
        photo
        createdAt
        updatedAt
        lastSignIn
        lastOnline
        online
        m2mUser
      }
      nextToken
    }
  }
`;
export const listUpdatesBySpecificOwner = /* GraphQL */ `
  query ListUpdatesBySpecificOwner(
    $owner: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUpdateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUpdatesBySpecificOwner(
      owner: $owner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        user {
          id
          cognitoId
          email
          name
          displayName
          firstName
          surname
          jobTitle
          officeLocation
          roles
          photo
          createdAt
          updatedAt
          lastSignIn
          lastOnline
          online
          m2mUser
        }
        id
        region
        service
        wwwContent
        wdgwContent
        pfnwContent
        segment
        wwwIsHighlight
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUpdatesByHighlight = /* GraphQL */ `
  query ListUpdatesByHighlight(
    $wwwIsHighlight: String
    $sortDirection: ModelSortDirection
    $filter: ModelUpdateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUpdatesByHighlight(
      wwwIsHighlight: $wwwIsHighlight
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        user {
          id
          cognitoId
          email
          name
          displayName
          firstName
          surname
          jobTitle
          officeLocation
          roles
          photo
          createdAt
          updatedAt
          lastSignIn
          lastOnline
          online
          m2mUser
        }
        id
        region
        service
        wwwContent
        wdgwContent
        pfnwContent
        segment
        wwwIsHighlight
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUpdatesByHighlightBySegment = /* GraphQL */ `
  query ListUpdatesByHighlightBySegment(
    $wwwIsHighlight: String
    $segment: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUpdateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUpdatesByHighlightBySegment(
      wwwIsHighlight: $wwwIsHighlight
      segment: $segment
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        user {
          id
          cognitoId
          email
          name
          displayName
          firstName
          surname
          jobTitle
          officeLocation
          roles
          photo
          createdAt
          updatedAt
          lastSignIn
          lastOnline
          online
          m2mUser
        }
        id
        region
        service
        wwwContent
        wdgwContent
        pfnwContent
        segment
        wwwIsHighlight
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUpdatesBySegment = /* GraphQL */ `
  query ListUpdatesBySegment(
    $segment: String
    $sortDirection: ModelSortDirection
    $filter: ModelUpdateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUpdatesBySegment(
      segment: $segment
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        user {
          id
          cognitoId
          email
          name
          displayName
          firstName
          surname
          jobTitle
          officeLocation
          roles
          photo
          createdAt
          updatedAt
          lastSignIn
          lastOnline
          online
          m2mUser
        }
        id
        region
        service
        wwwContent
        wdgwContent
        pfnwContent
        segment
        wwwIsHighlight
        createdAt
        owner
        updatedAt
      }
      nextToken
    }
  }
`;
