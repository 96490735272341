import React, { cloneElement } from "react";
import useAuth from "../hooks/useAuth";
import { PERMISSIONS } from "../permissionMaps";
import AccessDeniedPage from "../components/Pages/AccessDeniedPage";

const hasPermission = ({ permissions, scopes }) => {

    return permissions?.some(permission => scopes.includes(permission))
};

const getPermissions = ({ roles }) => {
    var permissions = PERMISSIONS[roles?.role]
    var addOnPerms = []
    if (roles?.additionalRoles.length > 0) {
        roles.additionalRoles.forEach((role) => {
            if (!addOnPerms.includes(PERMISSIONS[role])) {
                addOnPerms.push(PERMISSIONS[role])
            }
        })
    }
    var allPerms = permissions

    addOnPerms?.map((addOnArray) => allPerms = allPerms?.concat(addOnArray))
    return allPerms
}

export default function PermissionsGateContext({
    children,
    hideComponent,
    errorProps = null,
    scopes = []
}) {
    const { user } = useAuth();

    if (scopes.length > 0) {
        var roles = []
        if (user?.roles !== undefined) {
            roles = JSON.parse(user?.roles)
        }
        const permissions = getPermissions({ roles });

        const permissionGranted = hasPermission({ permissions, scopes });

        if (!permissionGranted && !errorProps) {
            if (hideComponent) {
                return (<React.Fragment></React.Fragment>)
            }
            return (<AccessDeniedPage />)
        }

        if (!permissionGranted && errorProps)
            return cloneElement(children, { ...errorProps });
    }

    return (<React.Fragment>{children}</React.Fragment>);
}
