import React, { useState, useEffect } from "react";
import { Chip, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import StandardCard from "./StandardCard";
import moment from "moment";
import {
  LocationOnRounded,
  LocationOffRounded,
} from "@material-ui/icons";
import { LocationOn } from "@mui/icons-material";
import { DoneRounded, PriorityHighRounded, BlockRounded, InfoRounded, WarningRounded, ErrorRounded } from '@material-ui/icons';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
    // display: 'inline-flex',
    textAlign: "center",
  },
  pos: {
    marginBottom: 12,
    fontSize: 12,
  },
  statusChip: {
    marginBottom: 6,
  },
  statusSeeMore: {
    textTransform: "none",
  },
  // card: {
  //     // Provide some spacing between cards
  //     margin: 16,
  //     //maxWidth: 370,
  //     maxWidth: 275,

  //     // Use flex layout with column direction for components in the card
  //     // (CardContent and CardActions)
  //     display: "flex",
  //     flexDirection: "column",
  // },
  borderCard: {
    borderStyle: "solid",
    borderColor: theme.palette.background.default,
    padding: "16px",
  },
  chipGreen: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white
  },
  chipFYI: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white
  },
  chipMinor: {
    backgroundColor: theme.palette.focus.main,
    color: theme.palette.common.black
  },
  chipMajor: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.black
  },
  chipCritical: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white
  },
  regionNone: {
    backgroundColor: theme.palette.common.grayT5,
    opacity: 0.5,
    color: theme.palette.common.black
  },
  // animatedItem: {
  //   opacity: 1,
  // },
  regions: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  region: {
    display: "flex",
  },
  cardItem: {
    display: "flex",
    justifyContent: "center",
  },
  card: {
    // Use flex layout with column direction for components in the card
    // (CardContent and CardActions)
    display: "flex",
    justifyContent: 'center',
    // Justify the content so that CardContent will always be at the top of the card,
    // and CardActions will be at the bottom
    //justifyContent: "space-between",
    height: "100%",
  },
  openForTimer: {
    backgroundColor: theme.palette.common.grayT5,
    color: theme.palette.common.black,
    padding: 12,
    textAlign: 'center',
    borderRadius: '24px',
  },
  openForTimeSection: {
    display: 'flex',
    justifyContent: 'space-around'
  },
}));

const DashboardServiceCard = ({ service, user }) => {
  const classes = useStyles();
  const [cardService, setCardService] = useState({});
  const [incidentOpenFor, setincidentOpenFor] = useState({
    days: "0",
    hours: "0",
    minutes: "0",
  });

  const regionConstants = {
    Europe: "EU",
    "North America": "NA",
    "South America": "SA",
    "Asia Pacific": "APAC",
    "Middle East and Africa": "MEA",
  };

  const statusConstants = {
    "Major Outage": "This service is experiencing a full outage.",
    "Partial Outage": "This service is experiencing a partial outage.",
    "Degraded Performance":
      "This service is experiencing degraded performance.",
    Operational: "This service is operational.",
  };

  const handleStatusChip = (size) => {
    switch (service?.Incident?.classification) {
      case "FYI":
        return (
          <Chip
            icon={<InfoRounded />}
            label="FYI"
            color="primary"
            size={size}
            className={clsx(classes.animatedItem, classes.chipFYI)}
          />
        );
      case "Minor":
        return (
          <Chip
            icon={<ErrorRounded />}
            label="Minor"
            color="primary"
            size={size}
            className={clsx(classes.animatedItem, classes.chipMinor)}
          />
        );
      case "Major":
        return (
          <Chip
            icon={<WarningRounded />}
            label="Major"
            color="primary"
            size={size}
            className={clsx(classes.animatedItem, classes.chipMajor)}
          />
        );
      case "Critical":
        return (
          <Chip
            icon={<DangerousRoundedIcon />}
            label="Critical"
            color="primary"
            size={size}
            className={clsx(classes.animatedItem, classes.chipCritical)}
          />
        );
      default:
        return (
          <Chip
            icon={<DoneRounded />}
            label="Operational"
            color="primary"
            size={size}
            className={clsx(classes.animatedItem, classes.chipGreen)}
          />
        );
    }
  };

  const handleRegionChip = (region) => {
    var regions = service?.Incident?.regions;
    var serviceRegions = service?.Regions;
    if (regions !== null && regions !== undefined) {
      regions = JSON.parse(regions);
      if (
        serviceRegions[region] === "NotAvailable" ||
        serviceRegions[region] === "Pending"
      ) {
        return (
          <Grid item xs={2}>
            <Chip
              icon={<LocationOffRounded />}
              label={regionConstants[region]}
              color="primary"
              className={clsx(
                classes.animatedItem,
                classes.regionNone,
                classes.region
              )}
            />
          </Grid>
        );
      }
      if (
        regions[region] !== null &&
        regions[region] !== undefined
      ) {
        return (
          <Grid item xs={2}>
            <Chip
              icon={<LocationOffRounded />}
              label={regionConstants[region]}
              color="primary"
              className={clsx(
                classes.animatedItem,
                classes.chipCritical,
                classes.region
              )}
            />
          </Grid>
        );
      }
    }
    return (
      <Grid item xs={2}>
        <Chip
          icon={<LocationOnRounded />}
          label={regionConstants[region]}
          color="primary"
          className={clsx(
            classes.animatedItem,
            classes.chipGreen,
            classes.region
          )}
        />
      </Grid>
    );
  };

  useEffect(() => {
    setCardService(service);
  }, [service]);

  useEffect(() => {
    const interval = setInterval(() => {
      handleDate(service);
    }, 1000);
    return () => clearInterval(interval);
  }, [service]);

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  const handleDate = () => {
    const formatTime = (time) => {
      return time < 10 ? `0${time}` : time;
    };

    const date1 = new Date();
    const date2 = new Date(moment.utc(service.Incident?.createdAt));
    const milliseconds = Math.abs(date1 - date2);

    var countDays = 24 * 60 * 60 * 1000;
    var countHours = 60 * 60 * 1000;
    var days = Math.floor(milliseconds / countDays);
    var hours = Math.floor((milliseconds - days * countDays) / countHours);
    var minutes = Math.round(
      (milliseconds - days * countDays - hours * countHours) / 60000
    );
    if (minutes === 60) {
      hours++;
      minutes = 0;
    }
    if (hours === 24) {
      days++;
      hours = 0;
    }
    setincidentOpenFor({ days, hours, minutes });
  };

  const cardContent = (
    <Grid container spacing={3} className={classes.card}>
      <Grid item xs={12} className={classes.cardItem}>
        <div className={classes.title}>
          <div className={classes.statusChip}>{handleStatusChip("small")}</div>
          <Typography
            variant="h3"
            gutterBottom
            className={classes.serviceTitle}
          >
            {cardService.ServiceAssetName}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} className={classes.cardItem}>
        <Typography variant="h4">
          {`${statusConstants[service?.Incident?.serviceStatus]}`}
        </Typography>
      </Grid>
      <Grid
        item xs={6}
        className={classes.cardItem}
      >
        {/* <Typography
          variant="h4"
          className={classes.subtitle}
        >
          Incident Open For
        </Typography> */}
        <Grid
          container spacing={0}
          className={classes.openForTimer}
        >
          <Grid
            item xs={12}
            className={classes.openForTimeSection}
          >
            <Grid
              item xs={3}
              className={classes.openForTimeSection}
            >
              <Typography
                variant="h3"
              >
                {`${incidentOpenFor.days}`}
              </Typography>
            </Grid>
            <Grid
              item xs={1}
              className={classes.openForTimeSection}
            >
              <Typography
                variant="h3"
              >
                <span className={classes.separator}>:</span>
              </Typography>
            </Grid>
            <Grid
              item xs={3}
              className={classes.openForTimeSection}
            >
              <Typography
                variant="h3"
              >
                {`${incidentOpenFor.hours}`}
              </Typography>
            </Grid>
            <Grid
              item xs={1}
              className={classes.openForTimeSection}
            >
              <Typography
                variant="h3"
              >
                <span className={classes.separator}>:</span>
              </Typography>
            </Grid>
            <Grid
              item xs={4}
              className={classes.openForTimeSection}
            >
              <Typography
                variant="h3"
              >
                {`${incidentOpenFor.minutes}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item xs={12}
            className={classes.openForTimeSection}
          >
            <Grid
              item xs={3}
              className={classes.openForTimeSection}
            >
              <Typography
                variant="h6"
              >
                days
              </Typography>
            </Grid>
            <Grid
              item xs={1}
            >
            </Grid>
            <Grid
              item xs={3}
              className={classes.openForTimeSection}
            >
              <Typography
                variant="h6"
              >
                hours
              </Typography>
            </Grid>
            <Grid
              item xs={1}
            >
            </Grid>
            <Grid
              item xs={4}
              className={classes.openForTimeSection}
            >
              <Typography
                variant="h6"
              >
                mins
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.regions}>
        {handleRegionChip("Europe")}
        {handleRegionChip("North America")}
        {handleRegionChip("South America")}
        {handleRegionChip("Asia Pacific")}
        {handleRegionChip("Middle East and Africa")}
      </Grid>
    </Grid>
  );

  return (
    <StandardCard cardContent={cardContent} className={classes.borderCard} />
  );
};

export default DashboardServiceCard;
