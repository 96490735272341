import React from "react";
import {
    Chip,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import StandardCard from './StandardCard'

import { DoneRounded, PriorityHighRounded, BlockRounded, InfoRounded, WarningRounded, ErrorRounded } from '@material-ui/icons';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 275,
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    title: {
        fontSize: 14,
        // display: 'inline-flex',
        textAlign: "center",
    },
    pos: {
        marginBottom: 12,
        fontSize: 12,
    },
    statusChip: {
        marginBottom: 6,
    },
    statusSeeMore: {
        textTransform: "none",
    },
    card: {
        // Provide some spacing between cards
        margin: 16,
        //maxWidth: 370,
        maxWidth: 275,

        // Use flex layout with column direction for components in the card
        // (CardContent and CardActions)
        display: "flex",
        flexDirection: "column",
    },
    borderCard: {
        borderStyle: 'solid',
        borderColor: theme.palette.background.default,
        padding: '16px'
    },
    chipGreen: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.common.white
      },
      chipFYI: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.common.white
      },
      chipMinor: {
        backgroundColor: theme.palette.focus.main,
        color: theme.palette.common.black
      },
      chipMajor: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.common.black
      },
      chipCritical: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.common.white
      },
    serviceTitle: {
        fontWeight: 600
    },
    animatedItem: {
        opacity: 1,
    },
}));

const DashboardServiceCard = ({ service, stats, segmentLead, user }) => {
    const classes = useStyles();

    const handleStatusChip = (size) => {
        switch (stats) {
            case 'FYI':
                return (
                    <Chip icon={<InfoRounded />} label="FYI" color="primary" size={size} className={clsx(classes.animatedItem, classes.chipFYI)} />
                )
            case 'Minor':
                return (
                    <Chip icon={<ErrorRounded />} label="Minor" color="primary" size={size} className={clsx(classes.animatedItem, classes.chipMinor)} />
                )
            case 'Major':
                return (
                    <Chip icon={<WarningRounded />} label="Major" color="primary" size={size} className={clsx(classes.animatedItem, classes.chipMajor)} />
                )
            case 'Critical':
                return (
                    <Chip icon={<DangerousRoundedIcon />} label="Critical" color="primary" size={size} className={clsx(classes.animatedItem, classes.chipCritical)} />
                )
            default: return (
                <Chip icon={<DoneRounded />} label="Operational" color="primary" size={size} className={clsx(classes.animatedItem, classes.chipGreen)} />
            )
        }
    }

    const cardContent = (
        <React.Fragment>
            <div className={classes.title}>
                <div className={classes.statusChip}>
                    {
                        handleStatusChip('small')
                    }
                </div>
                <Typography variant="h5" gutterBottom className={classes.serviceTitle}>
                    {service}
                </Typography>
            </div>
            <Typography variant="body2" component="p" className={classes.title}>
                {segmentLead}
            </Typography>
        </React.Fragment>
    )

    return (
        <StandardCard
            cardContent={cardContent}
            className={classes.borderCard} />
    );
};

export default DashboardServiceCard;
