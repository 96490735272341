import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';


const GuestGuard = ({ children }) => {
  const { isAuthenticated} = useAuth();

  if (isAuthenticated) {
    const redirectLocation = sessionStorage.getItem('loginRedirectPath')
    if(redirectLocation === null || redirectLocation === undefined)
    {
      return <Redirect to={'/home'} />;
    }
    return <Redirect to={redirectLocation} />;
  }

  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
