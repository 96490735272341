import { Amplify, Storage } from 'aws-amplify'
import config from './aws-exports'
require("dotenv").config();
export function configureAmplify() {

    var signInUrl;
    var signOutUrl;

    if (process.env.REACT_APP_AWS_BRANCH === "master") {
        signInUrl = "https://portal.sagelsm.com/";
        signOutUrl = "https://portal.sagelsm.com/";
    } else if (process.env.REACT_APP_AWS_BRANCH === "staging") {
        signInUrl = "https://portal-staging.sagelsm.com/";
        signOutUrl = "https://portal-staging.sagelsm.com/";
    }
    else if (process.env.REACT_APP_AWS_BRANCH === "develop") {
        signInUrl = "https://portal-dev.sagelsm.com/";
        signOutUrl = "https://portal-dev.sagelsm.com/";
    }
    else {
        signInUrl = `https://pr-${process.env.REACT_APP_AWS_PULL_REQUEST_ID}.${process.env.REACT_APP_AWS_APP_ID}.amplifyapp.com/`;
        signOutUrl = `https://pr-${process.env.REACT_APP_AWS_PULL_REQUEST_ID}.${process.env.REACT_APP_AWS_APP_ID}.amplifyapp.com/`;
    }

    if (process.env.NODE_ENV === "development") {
        signInUrl = "http://localhost:3000";
        signOutUrl = "http://localhost:3000";
    }

    const options = {
        ...config,
        userPoolId: process.env.REACT_APP_USERPOOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USERPOOL_WEB_CLIENT_ID,
        oauth: {
            domain: process.env.REACT_APP_DOMAIN,
            scope: ["openid"],
            redirectSignIn: signInUrl,
            redirectSignOut: signOutUrl,
            responseType: "code",
        },
        storage: sessionStorage,
    }

    Amplify.configure(options);

    localStorage.clear();
}