import React, {
  createContext,
  useEffect,
  useReducer
} from "react";
import useAuth from "../hooks/useAuth";
import { API, graphqlOperation } from "aws-amplify";
import { serviceRegisterGet } from "../graphql/queries";

const initialServiceRegisterState = {
  services: []
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIALISE": {
      const { services } = action.payload;

      return {
        ...state,
        services: services,
      };
    }
    case "ADD": {
      const { service } = action.payload;
      const { services } = state

      const newServicesData = [...services, service]

      return {
        ...state,
        services: newServicesData
      };
    }
    case "UPDATE": {
      const { service } = action.payload;
      const { services } = state
      var newServicesData = services

      const updateIndex = newServicesData.findIndex(updateService => updateService.Id === service.Id);
      const updatedService = newServicesData.splice(updateIndex, 1, service)

      return {
        ...state,
        services: newServicesData
      };
    }
    case "DELETE": {
      const { service } = action.payload;
      const { services } = state
      var newServicesData = services

      const updateIndex = newServicesData.findIndex(updateService => updateService.Id === service.Id);
      const deletedService = newServicesData.splice(updateIndex, 1)

      return {
        ...state,
        services: newServicesData
      };
    }
    default: {
      return { ...state };
    }
  }
};

const ServiceRegisterContext = createContext({
  ...initialServiceRegisterState,
});

export const ServiceProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialServiceRegisterState);

  const getServiceRegister = async () => {
    let data = null;

    try {
      const req = await API.graphql(
        graphqlOperation(serviceRegisterGet)
      );
      data = JSON.parse(req?.data?.serviceRegisterGet)
    } catch (err) {
      console.error(err);
    }
    return data;
  };

  useEffect(() => {
    const initData = async () => {
      try {
        const data = await getServiceRegister();
        if (data?.status === 200 && data?.services) {
          dispatch({
            type: "INITIALISE",
            payload: {
              services: data.services,
            },
          });
        }
      } catch (err) {
        console.error(err);
      }
    };
    initData();
    const interval = setInterval(() => {
      initData();
    }, 300000);
    return () => clearInterval(interval);
  }, []);

  const data = {
    ...state,
    dispatch: dispatch
  }

  return (
    <ServiceRegisterContext.Provider
      value={data}
    >
      {children}
    </ServiceRegisterContext.Provider >
  );
};

export default ServiceRegisterContext;
