import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import StandardCard from '../Cards/StandardCard';
import {
    Box,
    Card,
    CardContent,
    Container,
    Typography,
    makeStyles
} from '@material-ui/core';
import Error from '@material-ui/icons/Error'
import Page from './Page';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'relative',
    },
    cardContainer: {
        paddingBottom: 80,
        paddingTop: 80,
    },
    cardContent: {
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
    },
}));

const NotFoundPage = () => {
    const classes = useStyles();

    const cardContent = (
        <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
        >
            <div>
                <div
                    align="center"
                >
                    <Error
                        fontSize="large"
                    />
                </div>
                <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h1"
                    align="center"
                >
                    404
                </Typography>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                    This page could not be found
                </Typography>
            </div>
        </Box>
    )

    return (
        <Page
            className={classes.root}
            title="Not Found"
        >
            <Container
                className={classes.cardContainer}
                maxWidth="sm"
            >
                <StandardCard
                    cardContent={cardContent}
                    className={classes.cardContent}
                />
            </Container>
        </Page >
    );
};

export default NotFoundPage;
